import React, { FunctionComponent, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useHistory, RouteProps, RouteComponentProps } from 'react-router-dom';

import { Root } from '../pages/Root';
import { DeviceIndex } from '../pages/DeviceIndex';
import { GroupIndex } from '../pages/GroupIndex';
import { YoyakuView } from '../pages/YoyakuView';
import { Login } from '../pages/Login';
import { DeviceDetail } from '../pages/DeviceDetail';
import { DeviceRegister } from '../pages/DeviceRegister';
import { GroupRegister } from '../pages/GroupRegister';
import { GroupEdit } from '../pages/GroupEdit';

import { SlotSetting } from '../pages/SlotSetting';


export const Router: React.VFC =  () => {
  
  return (
    <Switch>
      <Route path="/" component={Root} exact />
      <Route path="/login" component={Login} exact />

      <Route path="/devices" component={DeviceIndex} exact />
      <Route path="/devices/details/:device_id" component={DeviceDetail} exact />
      <Route path="/devices/add" component={DeviceRegister} exact />

      <Route path="/groups" component={GroupIndex} exact />
      <Route path="/groups/edit" component={GroupRegister} exact />
      <Route path="/groups/edit/:id" component={GroupEdit} exact />

      <Route path="/slot" component={SlotSetting} exact />
      
      <Route path="/yoyaku" component={YoyakuView} exact />

    </Switch>
  );
};



// function PrivateRoute({children}:any, {...rest }) {
//   const { state:authState, loading:authLoading } = useUserAuth();
// console.log("PrivateRoute" + authState.isAuthrized );
//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//       authState.isAuthrized ?  (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: "/login",
//               state: { from: location }
//             }}
//           />
//         )
//       }
//     />
//   );
// }


