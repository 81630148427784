import React, { useCallback, useEffect, useRef, useState, memo } from 'react';
import _ from 'lodash';

// FullCalendar
import FullCalendar, {
  DateSelectArg,
  DatesSetArg,
  EventApi,
  EventClickArg,
  EventContentArg,
  EventInput,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import googleCalendarPlugin from '@fullcalendar/google-calendar';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Material UI components
import { Box, Button, Typography } from '@mui/material';

// types
import { HandlerSlot, ReqCopySlots, ReqGetSlots, ResSlots } from '../../types/ce-api/Slots';

// custom components
import { SlotDlg } from './SlotDlg';
import { CopyDlg } from './CopyDlg';
// import { CopyWeekDlg } from "./CopyWeekDlg";
import { CopyWeekDlgT } from './CopyWeekDlgT';
import { CopyMonthDlg } from './CopyMonthDlg';

// custom hooks
import { useSearchSlot } from '../../hooks/useSearchSlot';
import { useCopySlots } from '../../hooks/useCopySlots';
import { useGetAccountSlotList } from '../../hooks/useGetAccountSlotList';

type Props = {
  groupId: string;
};

export const SlotCalendar: React.VFC<Props> = memo((props: Props) => {
  const { groupId } = props;

  const [openNew, setOpenNew] = useState(false);
  const [slotData, setSlotData] = useState<Array<EventInput>>([]);

  const [mode, setMode] = useState(2);

  const [dateStr, setDateStr] = useState('2022-02-10');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  const [editId, setEditId] = useState('');
  const [slotTitle, setSlotTitle] = useState('');
  const [sametimeSlotNum, setSametimeSlotNum] = useState(0);
  const [sametimeGroupNum, setSametimeGroupNum] = useState(0);
  const [message, setMessage] = useState('');
  const [alertTo1, setAlertTo1] = useState('');
  const [alertTo2, setAlertTo2] = useState('');

  const [viewType, setViewType] = useState('timeGridWeek');
  const [viewTypeNo, setViewTypeNo] = useState(0);
  const [viewTitle, setViewTitle] = useState('');
  const [srcDate, setSrcDate] = useState('');

  const [openCopy, setOpenCopy] = useState(false);
  const [openCopyWeek, setOpenCopyWeek] = useState(false);
  const [openCopyMonth, setOpenCopyMonth] = useState(false);

  const { searchSlot, resSlotInfo } = useSearchSlot();
  const { getAccountSlotList, resGetSlot } = useGetAccountSlotList();

  const calendarRef = useRef<FullCalendar>(null!);

  // -----------------------------------------------------------------------------------
  useEffect(() => {
    // 初期の日付をセット
    const calendarApi = calendarRef.current.getApi();
    const start = calendarApi.view.currentStart;
    const src_date =
      String(start.getFullYear()) +
      '/' +
      ('00' + String(start.getMonth() + 1)).slice(-2) +
      '/' +
      ('00' + String(start.getDate())).slice(-2);
    const end_date =
      String(start.getFullYear()) +
      '/' +
      ('00' + String(start.getMonth() + 1)).slice(-2) +
      '/' +
      ('00' + String(start.getDate() + 6)).slice(-2);
    setViewTitle(src_date + ' – ' + end_date);
  }, []);

  // initial set.
  useEffect(() => {
    initialize();
  }, [groupId]);

  useEffect(() => {
    if (resGetSlot === undefined) {
      console.log('undefined');
      clearCalendar();
      return;
    }
    if (resGetSlot.slots === null) {
      console.log('NULL');
      clearCalendar();
      return;
    }
    setCalendar(resGetSlot!);
  }, [resGetSlot]);

  useEffect(() => {
    getSlotsData(viewType, viewTitle, groupId);
  }, [viewType, viewTitle, groupId]);

  const initialize = () => {
    console.log('initialize');
    setMode(0);

    setDateStr('2022-02-10');
    setStart('');
    setEnd('');

    setEditId('');
    setSlotTitle('');
    setSametimeSlotNum(0);
    setSametimeGroupNum(0);
    setMessage('');
    setAlertTo1('');
    setAlertTo2('');

    setViewTypeNo(0);
    setSrcDate('');
    setOpenCopy(false);
    setOpenCopyWeek(false);
    setOpenCopyMonth(false);
  };

  // -----------------------------------------------------------------------------------
  // add
  const openSlotDlg = (datestr: string, start: string, end: string) => {
    setDateStr(datestr);
    setStart(start);
    setEnd(end);
    setMessage('');
    setAlertTo1('');
    setAlertTo2('');
    setMode(1);
    setOpenNew(true);
  };
  const closeSlotDlg = async () => {
    setOpenNew(false);
    console.log('close_group=' + groupId);
    if (groupId != '') {
      var calendarApi = calendarRef.current.getApi();
      var type = calendarApi.view.type;
      var start_monday = calendarApi.view.currentStart;

      var date =
        String(start_monday.getFullYear()) +
        '-' +
        ('00' + String(start_monday.getMonth() + 1)).slice(-2) +
        '-' +
        ('00' + String(start_monday.getDate())).slice(-2);
      await getSlotsData(type, date, groupId);
    }
    initialize();
  };

  // -----------------------------------------------------------------------------------
  // set / clear events
  const setCalendar = (slotDatas: ResSlots) => {
    let eventDatas: EventInput[] = [{}];
    let bgColor: string;
    let displayType: string = 'auto';
    var calendarApi = calendarRef.current.getApi();

    if (slotDatas != null && slotDatas.slots.length > 0) {
      for (let i = 0; i < slotDatas.slots.length; i++) {
        console.log('for:' + slotDatas.slots[i].slot_title);
        if (slotDatas.slots[i].close_flg == 1) {
          bgColor = 'grey';
        } else {
          bgColor = 'lightblue';
          if (calendarApi.view.type == 'dayGridMonth') {
            displayType = 'auto';
          } else {
            displayType = 'auto';
          }
        }
        eventDatas.push({
          id: slotDatas.slots[i].slot_id,
          title: slotDatas.slots[i].slot_title,
          start: slotDatas.slots[i].start,
          end: slotDatas.slots[i].end,
          //rendering: 'background',
          color: bgColor,
          textColor: 'white',
          display: displayType,
        });
      }
    }

    setSlotData(eventDatas);
  };
  const clearCalendar = () => {
    let eventDatas: EventInput[] = [{}];
    console.log('clear!!!!!');
    setSlotData(eventDatas);
  };

  // -----------------------------------------------------------------------------------
  // Edit
  const openEditSlotDlg = async (id: string, w_datestr: string, w_start: string, w_end: string) => {
    await getOneSlot(groupId, id);
    setDateStr(w_datestr);
    setStart(w_start);
    setEnd(w_end);
    setEditId(id);
  };
  const getOneSlot = (group_id: string, slot_id: string) => {
    let req: HandlerSlot = {
      slot_id: slot_id,
      group_id: group_id,
      slot_title: '',
      app_uid: '',
      app_label: '',
      start_datetime: '',
      end_datetime: '',
      alert_to1: '',
      alert_to2: '',
      sametime_slot_num: 0,
      rgb: '',
      message: '',
      close_flg: 0,
      the_month: '',
      the_week_monday: '',
      the_day: '',
      src_date: '',
      dst_date: '',
      repeat_num: 0,
    };

    searchSlot(req, 1);
  };
  useEffect(() => {
    if (resSlotInfo === undefined) {
      console.log('undefined');
      return;
    }
    if (resSlotInfo.slots === null) {
      console.log('NULL');
      return;
    } else {
      console.log('KOKO:resSlotInfos');
      setSlotTitle(resSlotInfo.slots[0].slot_title);
      setSametimeSlotNum(resSlotInfo.slots[0].sametime_slot_num);
      setMessage(resSlotInfo.slots[0].message);
      setAlertTo1(resSlotInfo.slots[0].alert_to1);
      setAlertTo2(resSlotInfo.slots[0].alert_to2);

      setMode(2);
      setOpenNew(true);
    }
  }, [resSlotInfo]);
  // -----------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------
  //  Copy
  const onCopy = (selectedDate: any) => {
    var calendarApi = calendarRef.current.getApi();
    var start = calendarApi.view.currentStart;
    var src_date =
      String(start.getFullYear()) +
      '-' +
      ('00' + String(start.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + String(start.getDate())).slice(-2);
    var type = calendarApi.view.type == 'timeGridDay' ? 1 : calendarApi.view.type == 'timeGridWeek' ? 2 : 3;

    setSrcDate(src_date);
    setViewTypeNo(type);
    if (type == 1) {
      setOpenCopy(true);
    }
    if (type == 2) {
      setOpenCopyWeek(true);
    }
    if (type == 3) {
      setSrcDate(src_date.slice(0, 7));
      setOpenCopyMonth(true);
    }
  };
  const closeCopyDlg = async () => {
    setOpenCopy(false);
    setOpenCopyWeek(false);
    setOpenCopyMonth(false);
    console.log('close_group=' + groupId);
    if (groupId != '') {
      var calendarApi = calendarRef.current.getApi();
      var type = calendarApi.view.type;
      var start_monday = calendarApi.view.currentStart;

      var date =
        String(start_monday.getFullYear()) +
        '-' +
        ('00' + String(start_monday.getMonth() + 1)).slice(-2) +
        '-' +
        ('00' + String(start_monday.getDate())).slice(-2);
      await getSlotsData(type, date, groupId);
    }
    initialize();
  };

  // -----------------------------------------------------------------------------------
  // calendar events
  //選択した時のイベントハンドラ
  const handleDateSelect = useCallback((selectInfo: DateSelectArg) => {
    if (selectInfo.view.type != 'dayGridMonth') {
      //2022-02-07T06:00:00+09:00
      let w_dateStr = selectInfo.startStr.substring(0, 10);
      let w_start_time = selectInfo.startStr.substring(11, 16);
      let w_end_time = selectInfo.endStr.substring(11, 16);
      openSlotDlg(w_dateStr, w_start_time, w_end_time); //mode=1 add new event.
    }
  }, []);

  //登録されている予定を選択した時のイベントハンドラ
  const handleEventClick = useCallback((clickInfo: EventClickArg) => {
    if (clickInfo.view.type != 'dayGridMonth') {
      let edit_id = clickInfo.event.id;
      let w_dateStr = clickInfo.event.startStr.substring(0, 10);
      let w_start_time = clickInfo.event.startStr.substring(11, 16);
      let w_end_time = clickInfo.event.endStr.substring(11, 16);
      openEditSlotDlg(edit_id, w_dateStr, w_start_time, w_end_time); //mode=2 edit event.
    }
  }, []);

  const handleDateClick = useCallback((arg: DateClickArg) => {
    console.log(arg.view.type);
    if (arg.view.type == 'dayGridMonth') {
      var calendarApi = calendarRef.current.getApi();
      var type = calendarApi.view.type === 'dayGridMonth' ? 'timeGridDay' : 'dayGridMonth';
      var date = type === 'timeGridDay' ? arg.dateStr : null;
      calendarApi.changeView(type, date!);
    }
  }, []);

  const handlerDateChg = useCallback(
    (arg: DatesSetArg) => {
      setViewType(arg.view.type);
      setViewTitle(arg.view.title);
      console.log('KOKO!!!!!!!!!' + arg.view.title);
    },
    [groupId]
  );

  // view changeの時にサーバからSlots情報取得
  const getSlotsData = async (type: string, title: string, pgroup_id: string) => {
    console.log('in getSlotsData groupId=' + pgroup_id);
    if (pgroup_id === '') {
      console.log('group is not found');
      return;
    }

    console.log('getSlotsData--->' + title);
    var req: ReqGetSlots = {
      group_id: '',
      the_month: '',
      the_week_monday: '',
      the_day: '',
    };

    var getMode: number = 0;

    if (type == 'dayGridMonth') {
      req = {
        group_id: pgroup_id,
        the_month: title.replaceAll('/', '-').slice(0, 7),
        the_week_monday: '',
        the_day: '',
      };
      getMode = 1;
    }
    if (type == 'timeGridWeek') {
      req = {
        group_id: pgroup_id,
        the_month: '',
        the_week_monday: title.replaceAll('/', '-').slice(0, 10),
        the_day: '',
      };
      getMode = 2;
    }
    if (type == 'timeGridDay') {
      req = {
        group_id: pgroup_id,
        the_month: '',
        the_week_monday: '',
        the_day: title.replaceAll('/', '-'),
      };
      getMode = 3;
    }

    await getAccountSlotList(req, getMode); /* type = 1:month,2:week,3:day */
  };

  // -----------------------------------------------------------------------------------
  return (
    <Box>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, googleCalendarPlugin]}
        contentHeight={600}
        headerToolbar={{
          start: 'prev,next today',
          center: 'title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        dayMaxEvents={true}
        allDaySlot={false}
        dayHeaders={true}
        initialView={viewType}
        // slotDuration={'00:10:00'}
        // slotLabelInterval={'01:00'}
        // dayHeaderFormat={function (date) {
        //     const weekNum = date.date.marker.getDay();
        //     const week = ['日', '月', '火', '水', '木', '金', '土'][weekNum];
        //     return week;
        //   }}
        //contentHeight="auto"

        events={slotData}
        firstDay={1}
        titleFormat={{ year: 'numeric', month: '2-digit', day: '2-digit' }}
        locales={allLocales}
        locale="ja"
        // googleCalendarApiKey='AIzaSyA4XI0bQBIYoDuBvAZ9bCUYSTXgh5ysMis'
        // eventSources={[
        //     {
        //       googleCalendarId: 'japanese__ja@holiday.calendar.google.com',
        //       display:'background',
        //       color:"#f7e5ee",
        //       classNames: 'holiday-event'
        //     }
        // ]}
        //AIzaSyA4XI0bQBIYoDuBvAZ9bCUYSTXgh5ysMis
        //eventContent={renderEventContent}
        //selectMirror={true}
        //dayMaxEvents={false}
        navLinks={true}
        //businessHours={false}
        //handleWindowResize={true}

        //eventsSet={handleEvents}
        selectable={true}
        select={handleDateSelect}
        //editable={true}
        eventClick={handleEventClick}
        dateClick={handleDateClick}
        datesSet={handlerDateChg}
      />
      <SlotDlg
        open={openNew}
        edit_id={editId}
        groupId={groupId}
        dateStr={dateStr}
        start={start}
        end={end}
        slot_title={slotTitle}
        sametime_slot_num={sametimeSlotNum}
        sametime_group_num={sametimeGroupNum}
        message={message}
        alert_to1={alertTo1}
        alert_to2={alertTo2}
        mode={mode}
        closeSlotDlg={closeSlotDlg}
      />
      <Box sx={{ height: 20 }}></Box>
      <Box sx={{ width: 300 }}>
        <Button variant="outlined" onClick={onCopy}>
          <Typography component="div">
            {viewType == 'dayGridMonth'
              ? 'この月の設定を他の月にコピー'
              : viewType == 'timeGridWeek'
              ? 'この週の設定を他の週にコピー'
              : 'この日の予約を他の日にコピー'}
          </Typography>
        </Button>
      </Box>
      <CopyDlg
        copy_open={openCopy}
        group_id={groupId}
        src_date={srcDate}
        view_type={viewTypeNo}
        closeCopyDlg={closeCopyDlg}
      />
      <CopyWeekDlgT
        copy_open_week={openCopyWeek}
        group_id={groupId}
        src_date={srcDate}
        view_type={viewTypeNo}
        closeCopyDlg={closeCopyDlg}
      />
      <CopyMonthDlg
        copy_open_month={openCopyMonth}
        group_id={groupId}
        src_date={srcDate}
        view_type={viewTypeNo}
        closeCopyDlg={closeCopyDlg}
      />
    </Box>
  );
});
