import React, { useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// material UI components
import { Box, Stack, Button, TextField, Typography, FormControl, Select, MenuItem } from '@mui/material';

// custom
import { AppState } from '../../context/AppState';
import { ReqAddEditGetReservableInfo, ReqAddReservableInfoForm } from '../../types/ce-api/Yoyaku';
import { useEditReservableInfo } from '../../hooks/useEditReservableInfo';
import { useGetReservableInfo } from '../../hooks/useSearchReservableInfo';
import { useAddReservableInfo } from '../../hooks/useAddReservableInfo';

// バリデーションルール
const schema = yup.object({
  days: yup.number().typeError('数字を入れてください').moreThan(-1).integer(),
  cancel_days: yup.number().typeError('数字を入れてください').moreThan(-1).integer(),
  cancel_hours: yup.number().typeError('数字を入れてください').moreThan(-1).integer(),
  cancel_minutes: yup.number().typeError('数字を入れてください').moreThan(-1).integer(),
  ok_reserve_num: yup.number().typeError('数字を入れてください').moreThan(-1).integer(),
  sametime_group_num: yup.number().typeError('数字を入れてください').moreThan(-1).integer(),
});

const cancel_types = [
  { id: 1, name: '日' },
  { id: 2, name: '時間' },
  { id: 3, name: '分' },
];

type Props = {
  id: string;
};

// React Component
export const ReservableInfo: React.VFC<Props> = memo((props) => {
  const { id } = props;
  const appstate = AppState.getInstance;

  const history = useHistory();

  // custom hooks
  const { addReservableInfo } = useAddReservableInfo();
  const { editReservableInfo } = useEditReservableInfo();
  const { getReservableInfo, resReservableInfo } = useGetReservableInfo();

  // react hook form
  const {
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ReqAddReservableInfoForm>({
    defaultValues: {
      days: 0,
      cancel_days: 0,
      cancel_hours: 0,
      cancel_minutes: 0,
      ok_reserve_num: 0,
      sametime_group_num: 0,
      alert_to1: '',
      alert_to2: '',
    },
    resolver: yupResolver(schema),
  });
  const watchCancelType = watch('cancel_type');

  // グループ一覧ページへ
  const toGroupIndex = () => {
    history.push('/groups');
  };

  // reservable_info登録
  const sendReservableInfo: SubmitHandler<ReqAddReservableInfoForm> = async (data) => {
    let reqAddReservableInfo: ReqAddEditGetReservableInfo = {
      group_id: id,
      ac_uid: appstate.getUserId(),
      ac_label: appstate.getAccountName(),
      days: Number(data.days),
      cancel_days: data.cancel_type === 1 ? Number(data.cancel_days) : 0,
      cancel_hours: data.cancel_type === 2 ? Number(data.cancel_hours) : 0,
      cancel_minutes: data.cancel_type === 3 ? Number(data.cancel_minutes) : 0,
      ok_reserve_num: Number(data.ok_reserve_num),
      sametime_group_num: Number(data.sametime_group_num),
      alert_to1: data.alert_to1,
      alert_to2: data.alert_to2,
    };

    console.log(reqAddReservableInfo);
    if (resReservableInfo.ri_id == '') {
      await addReservableInfo(reqAddReservableInfo);
    } else {
      await editReservableInfo(resReservableInfo.ri_id, reqAddReservableInfo);
    }

    getReservableInfo(id);
  };

  // API
  useEffect(() => {
    getReservableInfo(id);
  }, []);
  useEffect(() => {
    setValue('days', resReservableInfo.days);
    setValue('cancel_days', resReservableInfo.cancel_days);
    setValue('cancel_hours', resReservableInfo.cancel_hours);
    setValue('cancel_minutes', resReservableInfo.cancel_minutes);
    setValue('ok_reserve_num', resReservableInfo.ok_reserve_num);
    setValue('sametime_group_num', resReservableInfo.sametime_group_num);
    setValue('alert_to1', resReservableInfo.alert_to1);
    setValue('alert_to2', resReservableInfo.alert_to2);
  }, [resReservableInfo]);

  return (
    <Box maxWidth="sm" sx={{ py: 5 }}>
      <Stack spacing={3} sx={{ mb: 5 }}>
        <Box sx={{ width: '100%' }}>
          <Typography align="left" component="div" sx={{ mb: 2 }}>
            予約可能期間
          </Typography>
          <Stack spacing={3}>
            <Controller
              render={({ field }) => (
                <TextField
                  required
                  label="〜日前から予約可能"
                  {...field}
                  error={'days' in errors}
                  helperText={errors.days?.message}
                  size="small"
                  type="number"
                />
              )}
              control={control}
              name="days"
              defaultValue={resReservableInfo.days}
            />
          </Stack>
        </Box>
        <Box>
          <Typography align="left" component="div" sx={{ mb: 2 }}>
            キャンセル可能期間
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {watchCancelType === 1 ? (
              <Controller
                render={({ field }) => (
                  <TextField
                    required
                    label="〜日"
                    {...field}
                    error={'cancel_days' in errors}
                    helperText={errors.cancel_days?.message}
                    size="small"
                    type="number"
                    sx={{ width: '80%' }}
                  />
                )}
                control={control}
                name="cancel_days"
                defaultValue={resReservableInfo.cancel_days}
              />
            ) : watchCancelType === 2 ? (
              <Controller
                render={({ field }) => (
                  <TextField
                    required
                    label="〜時間"
                    {...field}
                    error={'cancel_hours' in errors}
                    helperText={errors.cancel_hours?.message}
                    size="small"
                    type="number"
                    sx={{ width: '80%' }}
                  />
                )}
                control={control}
                name="cancel_hours"
                defaultValue={resReservableInfo.cancel_hours}
              />
            ) : (
              <Controller
                render={({ field }) => (
                  <TextField
                    required
                    label="〜分"
                    {...field}
                    error={'cancel_minutes' in errors}
                    helperText={errors.cancel_minutes?.message}
                    size="small"
                    type="number"
                    sx={{ width: '80%' }}
                  />
                )}
                control={control}
                name="cancel_minutes"
                defaultValue={resReservableInfo.cancel_minutes}
              />
            )}
            <FormControl fullWidth size="small" sx={{ maxWidth: '15%', mx: 2 }}>
              <Controller
                render={({ field }) => (
                  <Select
                    id="demo-simple-select"
                    {...field}
                    onChange={() => {
                      setValue('cancel_days', 0);
                      setValue('cancel_hours', 0);
                      setValue('cancel_minutes', 0);
                    }}
                  >
                    {cancel_types.length > 0
                      ? cancel_types.map(
                          // 選択したデバイスタイプのデバイスモデルのみ表示
                          (type) => (
                            <MenuItem key={type.id} value={type.id}>
                              {type.name}
                            </MenuItem>
                          )
                        )
                      : null}
                  </Select>
                )}
                control={control}
                name="cancel_type"
                defaultValue={resReservableInfo.cancel_hours !== 0 ? 2 : resReservableInfo.cancel_minutes !== 0 ? 3 : 1}
              />
            </FormControl>
            <Box sx={{ width: '10%', display: 'flex', alignItems: 'center' }}>前まで</Box>
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography align="left" component="div" sx={{ mb: 2 }}>
            一人が同時に予約できる数
          </Typography>
          <Stack spacing={3}>
            <Controller
              render={({ field }) => (
                <TextField
                  required
                  {...field}
                  error={'ok_reserve_num' in errors}
                  helperText={errors.ok_reserve_num?.message}
                  size="small"
                  type="number"
                />
              )}
              control={control}
              name="ok_reserve_num"
              defaultValue={resReservableInfo.ok_reserve_num}
            />
          </Stack>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography align="left" component="div" sx={{ mb: 2 }}>
            施設全体での同時予約可能人数
          </Typography>
          <Stack spacing={3}>
            <Controller
              render={({ field }) => (
                <TextField
                  required
                  {...field}
                  error={'sametime_group_num' in errors}
                  helperText={errors.sametime_group_num?.message}
                  size="small"
                  type="number"
                />
              )}
              control={control}
              name="sametime_group_num"
              defaultValue={resReservableInfo.sametime_group_num}
            />
          </Stack>
        </Box>
      </Stack>
      <div dir="rtl">
        <Button variant="contained" sx={{ my: 2, ml: 2 }} onClick={handleSubmit(sendReservableInfo)}>
          保存
        </Button>
        <Button variant="contained" sx={{ my: 2 }} color="secondary" onClick={toGroupIndex}>
          キャンセル
        </Button>
      </div>
    </Box>
  );
});
