import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';

// fullcalendar
import FullCalendar, {
  DateSelectArg,
  DatesSetArg,
  EventApi,
  EventClickArg,
  EventContentArg,
  EventInput,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import googleCalendarPlugin from '@fullcalendar/google-calendar';

// Material UI components
import { Box } from '@mui/material';

// types
import { AppUserType, ReqSearchReserve, ResSearchReserves } from '../../types/ce-api/Yoyaku';

// custom components
import { YoyakuDlg } from './YoyakuDlg';

// custom hooks
import { useGetAccountYoyakuList } from '../../hooks/useGetAccountYoyakuList';

type Props = {
  groupId: string;
};

export const YoyakuCalendar: React.VFC<Props> = (props: Props) => {
  const { groupId } = props;

  const [openYoyaku, setOpenYoyaku] = useState(false);
  const [slotData, setSlotData] = useState<Array<EventInput>>([]);

  const [mode, setMode] = useState(2);

  const [dateStr, setDateStr] = useState('2022-02-10');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [appUsers, setAppUsers] = useState<Array<AppUserType>>([]);
  const [editId, setEditId] = useState('');

  const [viewType, setViewType] = useState('timeGridWeek');
  const [viewTitle, setViewTitle] = useState('');

  const { getAccountYoyakuList, resGetYoyaku } = useGetAccountYoyakuList();

  const calendarRef = useRef<FullCalendar>(null!);

  // -----------------------------------------------------------------------------------
  useEffect(() => {
    // 初期の日付をセット
    const calendarApi = calendarRef.current.getApi();
    const start = calendarApi.view.currentStart;
    const src_date =
      String(start.getFullYear()) +
      '/' +
      ('00' + String(start.getMonth() + 1)).slice(-2) +
      '/' +
      ('00' + String(start.getDate())).slice(-2);
    const end_date =
      String(start.getFullYear()) +
      '/' +
      ('00' + String(start.getMonth() + 1)).slice(-2) +
      '/' +
      ('00' + String(start.getDate() + 6)).slice(-2);
    setViewTitle(src_date + ' – ' + end_date);
  }, []);

  // initial set.
  useEffect(() => {
    initialize();
  }, [groupId]);

  useEffect(() => {
    if (resGetYoyaku === undefined) {
      console.log('undefined');
      clearCalendar();
      return;
    }
    if (resGetYoyaku.reserves === null) {
      console.log('NULL');
      clearCalendar();
      return;
    }
    setCalendar(resGetYoyaku!);
  }, [resGetYoyaku]);

  useEffect(() => {
    getYoyakuData(viewType, viewTitle, groupId);
  }, [viewType, viewTitle, groupId]);

  const initialize = () => {
    console.log('initialize');
    setMode(0);
    setDateStr('2022-02-10');
    setStart('');
    setEnd('');

    setEditId('');
    setAppUsers([]);
  };

  // -----------------------------------------------------------------------------------
  // add
  const openYoyakuDlg = (datestr: string, start: string, end: string) => {
    setDateStr(datestr);
    setStart(start);
    setEnd(end);
    setMode(1);
    setOpenYoyaku(true);
  };
  const closeYoyakuDlg = async () => {
    setOpenYoyaku(false);
    console.log('close_group=' + groupId);
    if (groupId != '') {
      var calendarApi = calendarRef.current.getApi();
      var type = calendarApi.view.type;
      var start_monday = calendarApi.view.currentStart;

      var date =
        String(start_monday.getFullYear()) +
        '-' +
        ('00' + String(start_monday.getMonth() + 1)).substr(-2) +
        '-' +
        ('00' + String(start_monday.getDate())).substr(-2);
      await getYoyakuData(type, date, groupId);
    }
    initialize();
  };

  // -----------------------------------------------------------------------------------
  // set / clear events
  const setCalendar = (yoyakuDdatas: ResSearchReserves) => {
    let eventDatas: EventInput[] = [{}];
    let bgColor: string;
    let displayType: string = 'auto';
    var calendarApi = calendarRef.current.getApi();

    if (yoyakuDdatas != null && yoyakuDdatas.reserves.length > 0) {
      for (let i = 0; i < yoyakuDdatas.reserves.length; i++) {
        bgColor = 'lightblue';
        if (calendarApi.view.type == 'dayGridMonth') {
          displayType = 'auto';
        } else {
          displayType = 'auto';
        }

        eventDatas.push({
          id: yoyakuDdatas.reserves[i].reserve_id,
          title:
            yoyakuDdatas.reserves[i].app_users !== null && yoyakuDdatas.reserves[i].app_users.length !== 0
              ? yoyakuDdatas.reserves[i].app_users[0].app_label
              : '',
          start: yoyakuDdatas.reserves[i].start_datetime,
          end: yoyakuDdatas.reserves[i].end_datetime,
          //rendering: 'background',
          color: bgColor,
          textColor: 'white',
          display: displayType,
        });
      }
    }

    setSlotData(eventDatas);
  };
  const clearCalendar = () => {
    let eventDatas: EventInput[] = [{}];
    console.log('clear!!!!!');
    setSlotData(eventDatas);
  };

  // -----------------------------------------------------------------------------------
  // Edit
  // const openEditSlotDlg = async (id:string,w_datestr:string,w_start:string,w_end:string) =>{

  //     await getOneSlot(group_id,device_id,id);
  //     setDateStr(w_datestr);
  //     setStart(w_start);
  //     setEnd(w_end);
  //     setEditId(id);
  // }
  // const getOneSlot = (group_id:string,device_id:string,slot_id:string) =>{

  //      let req:HandlerSlot={
  //          slot_id: slot_id,
  //          group_id: group_id,
  //          device_id: device_id,
  //          slot_title: "",
  //          app_uid: "",
  //          app_label: "",
  //          start_datetime: "",
  //          end_datetime: "",
  //          alert_to1: "",
  //          alert_to2: "",
  //          sametime_slot_num: 0,
  //          rgb: "",
  //          message: "",
  //          close_flg: 0,
  //          the_month: "",
  //          the_week_monday: "",
  //          the_day: "",
  //          src_date: "",
  //          dst_date: "",
  //          repeat_num: 0
  //      }

  //      searchSlot(req,1);
  // }
  // useEffect(() => {
  //      if(resSlotInfo === undefined){
  //      console.log("undefined");
  //      return;
  //      }if(resSlotInfo.slots === null){
  //      console.log("NULL");
  //      return;
  //      }else{
  //      console.log("KOKO:resSlotInfos");
  //      setSlotTitle(resSlotInfo.slots[0].slot_title);
  //      setSametimeSlotNum(resSlotInfo.slots[0].sametime_slot_num);
  //      setMessage(resSlotInfo.slots[0].message);
  //      setAlertTo1(resSlotInfo.slots[0].alert_to1);
  //      setAlertTo2(resSlotInfo.slots[0].alert_to2);

  //      setMode(2);
  //      openYoyakuDlg(true);
  //      }
  // }, [resSlotInfo]);

  // -----------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------
  // calendar events
  //選択した時のイベントハンドラ
  const handleDateSelect = useCallback((selectInfo: DateSelectArg) => {
    if (selectInfo.view.type != 'dayGridMonth') {
      //2022-02-07T06:00:00+09:00
      let w_dateStr = selectInfo.startStr.substring(0, 10);
      let w_start_time = selectInfo.startStr.substring(11, 16);
      let w_end_time = selectInfo.endStr.substring(11, 16);
      openYoyakuDlg(w_dateStr, w_start_time, w_end_time); //mode=1 add new event.
    }
  }, []);

  //登録されている予定を選択した時のイベントハンドラ
  const handleEventClick = useCallback((clickInfo: EventClickArg) => {
    if (clickInfo.view.type != 'dayGridMonth') {
      let edit_id = clickInfo.event.id;
      let w_dateStr = clickInfo.event.startStr.substring(0, 10);
      let w_start_time = clickInfo.event.startStr.substring(11, 16);
      let w_end_time = clickInfo.event.endStr.substring(11, 16);
      openYoyakuDlg(w_dateStr, w_start_time, w_end_time);
    }
  }, []);

  const handleDateClick = useCallback((arg: DateClickArg) => {
    console.log(arg.view.type);
    if (arg.view.type == 'dayGridMonth') {
      var calendarApi = calendarRef.current.getApi();
      var type = calendarApi.view.type === 'dayGridMonth' ? 'timeGridDay' : 'dayGridMonth';
      var date = type === 'timeGridDay' ? arg.dateStr : null;
      calendarApi.changeView(type, date!);
    }
  }, []);

  const handlerDateChg = (arg: DatesSetArg) => {
    setViewType(arg.view.type);
    setViewTitle(arg.view.title);
    console.log('KOKO!!!!!!!!!' + arg.view.title);
  };

  // view changeの時にサーバからSlots情報取得
  const getYoyakuData = async (type: string, title: string, group_id: string) => {
    console.log('in getYoyakuData groupId=' + group_id);
    if (group_id === '') {
      console.log('group is not found');
      return;
    }

    var start_date = '';
    var start_datetime: string = '';
    var end_datetime: string = '';

    console.log('getYoyakuData--->' + title);
    var req: ReqSearchReserve = {
      group_id: groupId,
      start_datetime: '',
      end_datetime: '',
      reserve_id: '',
      app_users: [],
      slot_id: '',
      the_day: '',
      the_weekday: 0,
      the_start_time: '',
      the_end_time: '',
    };

    var getMode: number = 0;

    if (type == 'dayGridMonth') {
      start_date = title.replaceAll('/', '-').slice(0, 7);
      console.log('~~~~~~~~~>' + start_date);
      start_datetime = moment(start_date).startOf('month').format('YYYY-MM-DD HH:mm:ss');
      end_datetime = moment(start_date).add(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss');
    }
    if (type == 'timeGridWeek') {
      start_date = title.replaceAll('/', '-').slice(0, 10);
      console.log('~~~~~~~~~>' + start_date);
      start_datetime = moment(start_date).startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
      end_datetime = moment(start_date).add(1, 'week').startOf('week').format('YYYY-MM-DD HH:mm:ss');
    }
    if (type == 'timeGridDay') {
      start_date = title.replaceAll('/', '-').slice(0, 10);
      console.log('~~~~~~~~~>' + start_date);
      start_datetime = moment(start_date).format('YYYY-MM-DD HH:mm:ss');
      end_datetime = moment(start_date).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
    }
    req = {
      group_id: group_id,
      start_datetime: start_datetime,
      end_datetime: end_datetime,
      reserve_id: '',
      app_users: [],
      slot_id: '',
      the_day: '',
      the_weekday: 0,
      the_start_time: '',
      the_end_time: '',
    };
    getMode = 1;

    await getAccountYoyakuList(req, getMode); /* type = 1:term,2:user */
  };

  // -----------------------------------------------------------------------------------
  return (
    <Box>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, googleCalendarPlugin]}
        contentHeight={600}
        headerToolbar={{
          start: 'prev,next today',
          center: 'title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        dayMaxEvents={true}
        allDaySlot={false}
        dayHeaders={true}
        initialView={viewType}
        // slotDuration={'00:10:00'}
        // slotLabelInterval={'01:00'}
        // dayHeaderFormat={function (date) {
        //     const weekNum = date.date.marker.getDay();
        //     const week = ['日', '月', '火', '水', '木', '金', '土'][weekNum];
        //     return week;
        //   }}
        //contentHeight="auto"

        events={slotData}
        firstDay={1}
        titleFormat={{ year: 'numeric', month: '2-digit', day: '2-digit' }}
        locales={allLocales}
        locale="ja"
        // googleCalendarApiKey='AIzaSyA4XI0bQBIYoDuBvAZ9bCUYSTXgh5ysMis'
        // eventSources={[
        //     {
        //       googleCalendarId: 'japanese__ja@holiday.calendar.google.com',
        //       display:'background',
        //       color:"#f7e5ee",
        //       classNames: 'holiday-event'
        //     }
        // ]}
        //AIzaSyA4XI0bQBIYoDuBvAZ9bCUYSTXgh5ysMis
        //eventContent={renderEventContent}
        //selectMirror={true}
        //dayMaxEvents={false}
        navLinks={true}
        //businessHours={false}
        //handleWindowResize={true}

        //eventsSet={handleEvents}
        // selectable={true}
        // select={handleDateSelect}
        editable={true}
        eventDurationEditable={false}
        eventStartEditable={false}
        eventClick={handleEventClick}
        dateClick={handleDateClick}
        datesSet={handlerDateChg}
      />
      <YoyakuDlg
        open_yoyaku={openYoyaku}
        edit_id={editId}
        group_id={groupId}
        dateStr={dateStr}
        start={start}
        end={end}
        app_users={appUsers}
        mode={mode}
        closeYoyakuDlg={closeYoyakuDlg}
      />
      <Box sx={{ height: 20 }}></Box>
    </Box>
  );
};
