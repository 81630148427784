import React from 'react';
import axios from 'axios';
import { ReqAcEditDeviceStatus } from '../types/ce-api/Devices';
import { ApiUrl } from '../conf/config';

export const useEditDeviceStatus = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const editDeviceStatus = (id: string, device: ReqAcEditDeviceStatus) => {
    let token:string|null = sessionStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    setError(false);
    setLoading(true);

    axios
      .put(`${ApiUrl}/ac/api/v1/devices/${id}/status`, device,config)
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { editDeviceStatus, loading, error };
};
