import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button, Container, Stack, TextField, Typography, Box } from '@mui/material';

import { useLogin } from '../hooks/useLogin';
import { ReqLogin } from '../types/ce-api/auth';

// バリデーションルール
const schema = yup.object({
  email: yup.string().required('メールアドレスを入力してください'),
  password: yup.string().required('パスワードを入力してください'),
});

export const Login: React.VFC = () => {
  const { login, loading, error } = useLogin();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ReqLogin>({
    resolver: yupResolver(schema),
  });

  const onClickLogin: SubmitHandler<ReqLogin> = (data) => {
    login(data);
  };

  return (
    <Container maxWidth="sm" sx={{ width: '100%', height: '100vh' }}>
      <Box maxWidth="sm" sx={{ width: '100%', pt: '10vh' }}>
        <Typography variant="h5" align="left">
          ログイン
        </Typography>
        <form>
          <Stack spacing={4} sx={{ mt: 3 }}>
            <TextField
              required
              {...register('email')}
              error={'email' in errors}
              helperText={errors.email?.message}
              label="メールアドレス"
              type="email"
            />
            <TextField
              required
              {...register('password')}
              error={'password' in errors}
              helperText={errors.password?.message}
              label="パスワード"
              type="password"
            />

            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              onClick={handleSubmit(onClickLogin)}
              disabled={loading}
            >
              ログイン
            </Button>
          </Stack>
        </form>
      </Box>
    </Container>
  );
};
