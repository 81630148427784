import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  createTheme,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';

import { SubmitHandler, Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { grey } from '@mui/material/colors';

import { useSetSlot } from '../../hooks/useSetSlot';
import { useDeleteSlot } from '../../hooks/useDeleteSlot';

import { AppState } from '../../context/AppState';
import { ReqSetSlot, ReqDelSlots } from '../../types/ce-api/Slots';

// バリデーションルール
const schema = yup.object({
  //slot_title  : yup.string().required("タイトルは必須です。"),
  start_datetime: yup.string(),
  end_datetime: yup.string(),
  sametime_slot_num: yup.number(),
  message: yup.string(),
  alert_to1: yup.string().email('Invalid email format'),
  alert_to2: yup.string(),
});
const theme = createTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    subtitle1: {
      fontSize: 14,
      color: grey[800],
    },
  },
});

type Props = {
  open: boolean;
  edit_id: string;
  groupId: string;
  dateStr: string;
  start: string;
  end: string;
  slot_title: string;
  sametime_slot_num: number;
  sametime_group_num: number;
  message: string;
  alert_to1: string;
  alert_to2: string;
  mode: number;

  closeSlotDlg: () => void;
};

export const SlotDlg: React.VFC<Props> = (props: Props) => {
  const {
    open,
    edit_id,
    groupId,
    dateStr,
    start,
    end,
    slot_title,
    sametime_slot_num,
    sametime_group_num,
    message,
    alert_to1,
    alert_to2,
    mode,
    closeSlotDlg,
  } = props;
  const [editId, setEditId] = useState(edit_id);
  const [weekDay, setWeekDay] = useState(0);
  const [startText, setStartText] = useState(start);
  const [endText, setEndText] = useState(end);
  const [sameTimePersonNum, setSameTimePersonNum] = useState(1);
  const [maeSameTimePersonNum, setMaeSameTimePersonNum] = useState(0);
  const [slotTitleText, setSlotTitleText] = useState(' ');
  const [sameTimeFacNum, setSameTimeFacNum] = useState(5);
  const [maeSameTimeFacNum, setMaeSameTimeFacNum] = useState(0);
  const [messageText, setMessageText] = useState('');
  const [alertTo1, setAlertTo1] = useState('');
  const [alertTo2, setAlertTo2] = useState('');
  const [unReserveFlg, setUnReserveFlg] = useState(false);
  const [disabled, setDisabled] = useState(groupId === '');

  const { setSlot, resSetSlot, loading: setLoading, error: setError } = useSetSlot();
  const { delSlot, resDelSlot, loading: delLoading, error: delError } = useDeleteSlot();

  const appstate = AppState.getInstance;

  useEffect(() => {
    console.log('dlg mode=' + mode);
    if (mode == 1) {
      console.log('group_id=' + groupId);
      setSlotTitleText(' ');
      setStartText(start);
      setEndText(end);
      let dObj = new Date(dateStr);
      let wDay = dObj.getDay();
      setWeekDay(wDay);
      setSameTimePersonNum(1);
      setSameTimeFacNum(5);
      setUnReserveFlg(false);
      setMessageText('');
      setAlertTo1('');
      setAlertTo2('');
    } else if (mode == 2) {
      console.log('mode=2');
      console.log('groupId=' + groupId);
      setEditId(edit_id);
      setSlotTitleText(slot_title);
      setStartText(start);
      setEndText(end);
      let dObj = new Date(dateStr);
      let wDay = dObj.getDay();
      setWeekDay(wDay);
      console.log('same dlg=' + sametime_slot_num);

      setSameTimePersonNum(sametime_slot_num);
      setSameTimeFacNum(sametime_group_num);
      setMessageText(message);
      setAlertTo1(alert_to1);
      setAlertTo2(alert_to2);
      if (sametime_slot_num == 0 && sametime_group_num == 0) {
        setUnReserveFlg(true);
      } else {
        setUnReserveFlg(false);
      }
    }
  }, [mode]);

  useEffect(() => {
    setDisabled(groupId === '');
  }, [groupId]);

  const {
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ReqSetSlot>({
    defaultValues: {
      slot_id: '',
      group_id: groupId,
      slot_title: '',
      app_uid: '',
      app_label: '',
      start_datetime: '',
      end_datetime: '',
      sametime_slot_num: 5,
      message: '',
      alert_to1: '',
      alert_to2: '',
      close_flg: 0,
    },
    resolver: yupResolver(schema),
  });
  const Youbi = ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'];

  const HandleFlgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setUnReserveFlg(true);
      setMaeSameTimePersonNum(sameTimePersonNum);
      setMaeSameTimeFacNum(sameTimeFacNum);
      setSameTimePersonNum(0);
      setSameTimeFacNum(0);
    } else {
      setSameTimePersonNum(maeSameTimePersonNum);
      setSameTimeFacNum(maeSameTimeFacNum);
      setUnReserveFlg(false);
    }
  };
  const onSave: SubmitHandler<ReqSetSlot> = (data) => {
    if (mode == 2) {
      data.slot_id = editId;
    }
    data.group_id = groupId;
    data.slot_title = slotTitleText.trim();
    data.app_uid = appstate.getAccountId();
    data.app_label = appstate.getAccountName();
    data.start_datetime = dateStr + ' ' + startText + ':00';
    data.end_datetime = dateStr + ' ' + endText + ':00';
    data.sametime_slot_num = sameTimePersonNum;
    data.message = messageText;
    data.alert_to1 = alertTo1;
    data.alert_to2 = alertTo2;
    data.close_flg = unReserveFlg == true ? 1 : 0;

    console.log('close_flg=' + unReserveFlg);
    if (unReserveFlg == true) {
      data.sametime_slot_num = 0;
    }

    setSlot(data, mode);
    closeSlotDlg();
  };

  const onClose = () => {
    closeSlotDlg();
  };

  const onDelete = async () => {
    let result = window.confirm(`削除しようとしています。\n
よろしければ「OK」を押してください。\n
変更を意図していない場合(間違いの場合)
「キャンセル」を押してください。`);
    if (result) {
      let req: ReqDelSlots = {
        slot_id: editId,
      };
      await delSlot(req);
    }
    closeSlotDlg();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'xs'}>
      <DialogTitle>
        予約設定の追加({Youbi[weekDay]})：
        {mode == 2 ? (
          <IconButton
            color="primary"
            aria-label="settings"
            component="span"
            sx={{ ml: 1 }}
            onClick={() => {
              onDelete();
            }}
          >
            <DeleteIcon sx={{ color: '#757575' }} />
          </IconButton>
        ) : (
          ''
        )}
      </DialogTitle>
      <DialogContent>
        <ThemeProvider theme={theme}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              {' '}
            </Grid>
            {disabled && (
              <Grid item xs={8} sx={{ color: 'red' }}>
                {'グループを選択してください'}
              </Grid>
            )}
          </Grid>
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                {' '}
              </Grid>
              <Grid item xs={8}>
                <Box sx={{ width: '80%', height: 20 }}></Box>
                <Box>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            label="タイトル*"
                            value={slotTitleText}
                            onChange={(e) => {
                              setSlotTitleText(e.target.value);
                            }}
                            error={'slot_title' in errors}
                            helperText={errors.slot_title?.message}
                            size="small"
                          />
                        )}
                        control={control}
                        name="slot_title"
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            label="開始時間*"
                            value={startText}
                            onChange={(e) => {
                              setStartText(e.target.value);
                            }}
                            error={'start_datetime' in errors}
                            helperText={errors.start_datetime?.message}
                            size="small"
                          />
                        )}
                        control={control}
                        name="start_datetime"
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            label="終了時間*"
                            value={endText}
                            onChange={(e) => {
                              setEndText(e.target.value);
                            }}
                            error={'end_datetime' in errors}
                            helperText={errors.end_datetime?.message}
                            size="small"
                          />
                        )}
                        control={control}
                        name="end_datetime"
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            label="同時予約可能数*"
                            disabled={unReserveFlg}
                            value={sameTimePersonNum}
                            onChange={(e) => {
                              setSameTimePersonNum(Number(e.target.value));
                            }}
                            error={'sametime_slot_num' in errors}
                            helperText={errors.sametime_slot_num?.message}
                            size="small"
                          />
                        )}
                        control={control}
                        name="sametime_slot_num"
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            label="メッセージ"
                            value={messageText}
                            onChange={(e) => {
                              setMessageText(e.target.value);
                            }}
                            error={'message' in errors}
                            helperText={errors.sametime_slot_num?.message}
                            size="small"
                          />
                        )}
                        control={control}
                        name="message"
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            label="予約通知先e-mail"
                            value={alertTo1}
                            onChange={(e) => {
                              setAlertTo1(e.target.value);
                            }}
                            error={'alert_to1' in errors}
                            helperText={errors.sametime_slot_num?.message}
                            size="small"
                          />
                        )}
                        control={control}
                        name="alert_to1"
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            label="予約通知先e-mail(予備)"
                            value={alertTo2}
                            onChange={(e) => {
                              setAlertTo2(e.target.value);
                            }}
                            error={'alert_to2' in errors}
                            helperText={errors.sametime_slot_num?.message}
                            size="small"
                          />
                        )}
                        control={control}
                        name="alert_to2"
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        labelPlacement="end"
                        label={
                          <Typography whiteSpace="nowrap" variant="subtitle1">
                            予約不可
                          </Typography>
                        }
                        control={
                          <Checkbox
                            id="form-mon_close_flg"
                            onChange={HandleFlgChange}
                            checked={unReserveFlg ?? false}
                            sx={{ color: grey[800], '&.Mui-checked': { color: grey[600] } }}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ width: '80%', height: 20 }}> </Box>
                <div dir="rtl">
                  <Button onClick={handleSubmit(onSave)} variant="contained" disabled={disabled}>
                    <Typography component="div">
                      <Box sx={{ fontWeight: 'bold' }}> {mode == 1 ? '設　定' : '編　集'} </Box>
                    </Typography>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={1}>
                {' '}
              </Grid>
            </Grid>
            <Box sx={{ width: '80%', height: 10 }}> </Box>
          </Stack>
        </ThemeProvider>
      </DialogContent>
    </Dialog>
  );
};
