import { useState, useCallback } from 'react';
import axios from 'axios';
import { HandlerSlot, ReqCopySlots, ResponseStatus } from '../types/ce-api/Slots';
import { YoyakuApiUrl } from '../conf/config';

export const useCopySlots = () => {
  const [copyResult, setCopyResult] = useState<ResponseStatus>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const copySlots = useCallback(<T extends HandlerSlot | ReqCopySlots>(value: T, mode: number) => {
    // 1:day,2:week,3:month
    setError(false);
    setLoading(true);

    if (typeof value == `object`) {
      let req: HandlerSlot = {
        slot_id: '',
        group_id: '',
        slot_title: '',
        app_uid: '',
        app_label: '',
        start_datetime: '',
        end_datetime: '',
        alert_to1: '',
        alert_to2: '',
        sametime_slot_num: -1,
        rgb: '',
        message: '',
        close_flg: 0,
        the_month: '',
        the_week_monday: '',
        the_day: '',
        src_date: '',
        dst_date: '',
        repeat_num: 0,
      };

      req.group_id = value.group_id;
      req.src_date = value.src_date;
      req.dst_date = value.dst_date;
      if (mode == 2 || mode == 3) {
        req.repeat_num = value.repeat_num;
      }

      let token: string | null = sessionStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      let api: string = '';
      if (mode == 1) {
        api = 'slots/copyday';
      }
      if (mode == 2) {
        api = 'slots/copyweek';
      }
      if (mode == 3) {
        api = 'slots/copymonth';
      }
      console.log('api=' + api);

      if (token != null) {
        axios
          .post(`${YoyakuApiUrl}/ac/api/v1/${api}`, req, config)
          .then((res) => {
            console.log(res.data);

            setCopyResult(res.data);
          })
          .catch((error) => {
            console.log(error);
            setError(true);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, []);

  return { copySlots, copyResult, loading, error };
};
