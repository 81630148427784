import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// material UI components
import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Autocomplete,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { Template } from '../templates/Template';

import { useAccountDevicesList } from '../hooks/useAccountDevicesList';
import { useAddGroup } from '../hooks/useAddGroup';

import { ReqAddGroup } from '../types/ce-api/Groups';
import { ResAcGetDeviceList } from '../types/ce-api/Devices';
import { useCheckToken } from '../hooks/useCheckToken';
import { AppState } from '../context/AppState';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// バリデーションルール
const schema = yup.object({
  name: yup.string().required('必須項目です'),
});

// React Component
export const GroupRegister: React.VFC = () => {
  const history = useHistory();
  const [deviceList, setDeviceList] = useState<Array<ResAcGetDeviceList>>([]);

  // react hooks form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ReqAddGroup>({
    resolver: yupResolver(schema),
  });

  // custom hooks
  const { getAccountDevicesList, devices, notExists, loading, error } = useAccountDevicesList();
  const { addGroup, loading:addLoading, error:addError } = useAddGroup();
  const { checkToken } = useCheckToken();
  const appstate = AppState.getInstance;

  useEffect(() => {
    checkToken();
  }, []);

  // デバイス一覧ページへ
  const toGroupIndex = () => {
    history.push('/groups');
  };

  // デバイス登録
  const sendGroup: SubmitHandler<ReqAddGroup> = (data) => {
    console.log('register');
    console.log(data);
    let reqDeviceIds: Array<string> = [];
    for (let v of deviceList) {
      reqDeviceIds = [...reqDeviceIds, v.id];
    }
    data.device_ids = reqDeviceIds;
    addGroup(appstate.getAccountId(),data);
    if (!error && !loading) {
      alert('グループを登録しました。');
      toGroupIndex();
    }
  };

  // このアカウントのデバイスを最初に取得しておく
  useEffect(() => {
    getAccountDevicesList(appstate.getAccountId(),9);
  }, [getAccountDevicesList,appstate]);


  // API
  return (
    <Template title="グループ作成" menuId={2}>
      <Box maxWidth="sm" sx={{ py: 5 }}>
        <Stack spacing={2} sx={{ mb: 5 }}>
          <TextField
            required
            label="グループ名"
            {...register('name')}
            error={'name' in errors}
            helperText={errors.name?.message}
            size="small"
          />
          <TextField
            label="メモ"
            {...register('memo')}
            error={'memo' in errors}
            helperText={errors.memo?.message}
            size="small"
          />
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            onChange={(event, newValue) => {
              setDeviceList([...newValue]);
            }}
            options={devices}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.name}
              </li>
            )}
            style={{ width: '100%' }}
            renderInput={(params) => (
              <TextField {...params} label="デバイス" placeholder="デバイスを選択" size="small" />
            )}
          />
        </Stack>
        <div dir="rtl">
          <Button variant="contained" sx={{ my: 2, ml: 2 }} onClick={handleSubmit(sendGroup)}>
            作成
          </Button>
          <Button variant="contained" sx={{ my: 2 }} color="secondary" onClick={toGroupIndex}>
            キャンセル
          </Button>
        </div>
      </Box>
    </Template>
  );
};
