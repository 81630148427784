import { useState, useCallback } from 'react';
import axios from 'axios';

import { YoyakuApiUrl } from '../conf/config';
import { HandlerSlot, ReqDelSlots, ResAddSlot } from '../types/ce-api/Slots';

export const useDeleteSlot = () => {
  const [ resDelSlot, setResDelSlot ]     = useState<ResAddSlot>();
  const [ loading, setLoading       ]     = useState(false);
  const [ error, setError           ]     = useState(false);
  
  
  const delSlot = useCallback(<T extends HandlerSlot | ReqDelSlots>(value:T) => {
    setError(false);
    setLoading(true);


      let token:string|null = sessionStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let api:string="";
      api = "slots/"+value.slot_id;

      if(token != null){
          axios
            .delete(`${YoyakuApiUrl}/ac/api/v1/${api}`,config)
            .then((res) => {
              console.log(res.data);
              setResDelSlot(res.data);
            })
            .catch((error) => {
              console.log(error);
              setError(true);
            })
            .finally(() => {
              setLoading(false);
            });
      }

  }, []);

  return { delSlot, resDelSlot, loading, error };
};

