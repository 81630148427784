import { useState, useCallback } from 'react';
import axios from 'axios';

import { ReqGetAccountLinkageGroups,ResGetGroups } from '../types/ce-api/Groups';
import { ApiUrl } from '../conf/config';

export const useAccountGroups = () => {
  const [groups, setGroups] = useState<Array<ResGetGroups>>([]);
  const [notExists, setNotExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getAccountGroups = useCallback((account_id:string) => {
    if(account_id != undefined || account_id != 0){

      let token:string|null = sessionStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      setError(false);
      setLoading(true);

      axios
        .get(`${ApiUrl}/ac/api/v1/groups`, config)
        .then((res) => {
          console.log(res.data.linkage_groups);
          if (res.data.linkage_groups === null) {
            setNotExists(true);
            setGroups([]);
          } else {
            setGroups(res.data.linkage_groups);
          }
        })
        .catch((error) => {
          console.log(error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
      });
    }
  }, []);

  return { getAccountGroups, groups, notExists, loading, error };
};
