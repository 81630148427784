import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import {
  CssBaseline,
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
} from '@mui/material';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import EventIcon from '@mui/icons-material/Event';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { Theme } from '../conf/theme';
import { useLogout } from '../hooks/useLogout';

import { AppState } from '../context/AppState';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const SLink = styled(Link)({
  textDecoration: 'none',
  color: Theme.palette.text.primary,
});

type Props = {
  children: React.ReactNode;
  title: string;
  menuId: number;
};

const drawerWidth = 240;

export const Template: React.VFC<Props> = (props) => {
  const [slotOpen, setSlotOpen] = useState(true);
  const [ohOpen, setOhOpen] = useState(true);
  const { children, title, menuId } = props;
  const { logout } = useLogout();
  const appstate = AppState.getInstance;

  const slotHandleClick = () => {
    setSlotOpen(!slotOpen);
  };
  const ohHandleClick = () => {
    setOhOpen(!ohOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            TRIBAWL Dashboard
          </Typography>
          <Button
            color="inherit"
            onClick={() => {
              alert(appstate.getAccountId());
            }}
          >
            {appstate.getAccountName()}
          </Button>
          <Button
            color="inherit"
            onClick={() => {
              logout();
            }}
          >
            ログアウト
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <SLink to="/devices">
              <ListItem button sx={{ bgcolor: menuId === 0 ? '#eee' : '#fff' }}>
                <ListItemIcon>
                  <PhonelinkSetupIcon />
                </ListItemIcon>
                <ListItemText primary="顔認証端末管理" />
              </ListItem>
            </SLink>
            <SLink to="/groups">
              <ListItem button sx={{ bgcolor: menuId === 1 ? '#eee' : '#fff' }}>
                <ListItemIcon>
                  <AutoAwesomeMotionIcon />
                </ListItemIcon>
                <ListItemText primary="グループ管理" />
              </ListItem>
            </SLink>

            <SLink to="/slot">
              <ListItem button sx={{ bgcolor: menuId === 2 ? '#eee' : '#fff' }}>
                <ListItemIcon>
                  <EventIcon />
                </ListItemIcon>
                <ListItemText primary="予約設定" />
              </ListItem>
            </SLink>

            <SLink to="/yoyaku">
              <ListItem button sx={{ bgcolor: menuId === 3 ? '#eee' : '#fff' }}>
                <ListItemIcon>
                  <EventNoteIcon />
                </ListItemIcon>
                <ListItemText primary="予約状況確認" />
              </ListItem>
            </SLink>
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Typography component="h2" variant="h5" color="inherit" noWrap>
          {title}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};
