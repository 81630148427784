import { useState, useCallback } from 'react';
import axios from 'axios';
import { YoyakuApiUrl } from '../conf/config';
import { ReqAddEditGetReservableInfo, ReservableInfo } from '../types/ce-api/Yoyaku';

export const useGetReservableInfo = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [resReservableInfo, setResreservableInfo] = useState<ReservableInfo>({
    ri_id: '',
    group_id: '',
    ac_uid: '',
    ac_label: '',
    days: 0,
    cancel_days: 0,
    cancel_hours: 0,
    cancel_minutes: 0,
    ok_reserve_num: 0,
    sametime_group_num: 0,
    alert_to1: '',
    alert_to2: '',
  });

  const getReservableInfo = useCallback(async (group_id: string) => {
    const token: string | null = sessionStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const req: ReqAddEditGetReservableInfo = {
      group_id: group_id,
      ac_uid: '',
      ac_label: '',
      days: 0,
      cancel_days: 0,
      cancel_hours: 0,
      cancel_minutes: 0,
      ok_reserve_num: 0,
      sametime_group_num: 0,
      alert_to1: '',
      alert_to2: '',
    };

    setError(false);
    setLoading(true);

    try {
      const res = await axios.post(`${YoyakuApiUrl}/ac/api/v1/rsrvinfos/search`, req, config);
      console.log(res.data);
      if (res.status === 200) {
        setResreservableInfo(res.data.ris[0]);
      }
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return { getReservableInfo, resReservableInfo, loading, error };
};
