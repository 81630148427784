import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { ApiUrl } from '../conf/config';

import { ReqLogin } from '../types/ce-api/auth';
import { AppState } from '../context/AppState';

export const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();

  const login = useCallback((body: ReqLogin) => {
    const appstate = AppState.getInstance;

    setError(false);
    setLoading(true);

    axios
      .post(`${ApiUrl}/account/login`, body)
      .then((res) => {
        // tokenを保存
        sessionStorage.setItem('token', res.data.token);

        //ここでユーザ情報セットして/に遷移する
        if (res.data.account_id === null) {
          history.push({ pathname: '/login' });
        } else {
          console.log('login ok');
          appstate.setAccountId(res.data.account_id);
          appstate.setAccountName(res.data.name);
          appstate.setAccountRole(res.data.role_id);
          appstate.setIsLogin(true);
          appstate.setUserId(res.data.user_id);
          history.push({ pathname: '/devices' });
        }
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { login, loading, error };
};
