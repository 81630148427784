import React from 'react';
import axios from 'axios';
import { ReqAddGroup } from '../types/ce-api/Groups';
import { ApiUrl } from '../conf/config';

export const useAddGroup = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const addGroup = (account_id:string,group: ReqAddGroup) => {
    console.log("addGroup::account_id="+account_id);
    
    if(account_id != "" || account_id != undefined){
      let token:string|null = sessionStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      group.account_id = account_id;
      
      setError(false);
      setLoading(true);
      axios
        .post(`${ApiUrl}/ac/api/v1/groups`, group,config)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.error(error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return { addGroup, loading, error };
};
