import React, { useCallback, useState } from 'react';

import { ResAcDeviceInfo,DevicesList } from '../types/ce-api/Devices';

export const useFormattingDevices = (devices: Array<ResAcDeviceInfo>) => {
  const [formattedDevices, setFormattedDevices] = useState<Array<DevicesList>>([]);

  const deviceType = (id: number) => {
    let deviceTypeName = '';
    switch (id) {
      case 1:
        deviceTypeName = '顔認証管理BOX';
        break;
      case 2:
        deviceTypeName = 'Pad';
        break;
      case 3:
        deviceTypeName = 'AIBOX';
        break;
      case 4:
        deviceTypeName = 'thermo';
        break;
    }
    return deviceTypeName;
  };

  const formatDevices = () => {
    const data = devices.map((device) => ({
      id: device.id,
      account_id: device.account_id,
      name: device.name,
      manage_name: device.manage_name,
      type: device.type,
      type_name: deviceType(device.type),
      activation: device.activation,
      status: device.status,
      is_monitoring: device.is_monitoring,
      is_connect: device.is_connect,
      lan_ip: device.lan_ip,
      group_id: device.group_id,
      group_name: device.group_name,
      group_memo: device.group_memo,
    }));
    console.log('data: ', data);
    setFormattedDevices(data);
  };

  return { formatDevices, formattedDevices };
};
