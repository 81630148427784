import React, { useState, memo, useEffect } from 'react';
import { useCheckToken } from '../hooks/useCheckToken';

import { Template } from '../templates/Template';
import { Typography } from '@mui/material';

export const Root: React.VFC = memo(() => {
  const { checkToken } = useCheckToken();
  useEffect(() => {
    checkToken();
  }, []);

  return (
    <Typography>
            loading...
    </Typography>
  );
});
