import { useState, useCallback } from 'react';
import axios from 'axios';
import { ResDeviceModelListType } from '../types/ce-api/DeviceModels';
import { ApiUrl } from '../conf/config';

export const useAllDeviceModels = () => {
  const [deviceModels, setDeviceModels] = useState<Array<ResDeviceModelListType>>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getDeviceModels = useCallback(() => {
    setError(false);
    setLoading(true);

    axios
      .get(`${ApiUrl}/admin/api/v1/device-models/list`)
      .then((res) => {
        console.log(res.data);
        if (res.data.device_models === null) {
          setDeviceModels([]);
        } else {
          setDeviceModels(res.data.device_models);
        }
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { getDeviceModels, deviceModels, loading, error };
};
