import React, { useState, useEffect } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';

// material UI components
import { Box, Button, Tabs, Tab } from '@mui/material';

// custom
import { Template } from '../templates/Template';
import { TabPanel } from '../components/utilities/TabPanel';

import { useCheckToken } from '../hooks/useCheckToken';
import { GroupInfo } from '../components/groups/GroupInfo';
import { ReservableInfo } from '../components/groups/ReservableInfo';

type Props = RouteComponentProps<{
  id: string;
}>;

// React Component
export const GroupEdit: React.VFC<Props> = (props) => {
  // token チェック ---------------------------------
  const { checkToken } = useCheckToken();

  useEffect(() => {
    checkToken();
  }, []);
  // ----------------------------------------------

  const { id } = props.match.params;
  const [tabId, setTabId] = useState(0);
  const history = useHistory();

  // デバイス一覧ページへ
  const toGroupIndex = () => {
    history.push('/groups');
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabId(newValue);
  };

  return (
    <Template title="施設情報編集" menuId={1}>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            mt: 2,
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            minWidth: 650,
          }}
        >
          <Tabs value={tabId} onChange={handleChange} aria-label="device tabs" sx={{ flexGrow: 1 }}>
            <Tab label="施設基本設定" />
            <Tab label="予約設定" />
          </Tabs>
          <div dir="rtl">
            <Button variant="contained" onClick={toGroupIndex} color="secondary" size="small">
              一覧に戻る
            </Button>
          </div>
        </Box>
        <TabPanel value={tabId} index={0}>
          <GroupInfo id={id} />
        </TabPanel>
        <TabPanel value={tabId} index={1}>
          <ReservableInfo id={id} />
        </TabPanel>
      </Box>
    </Template>
  );
};
