import { useState, useCallback } from 'react';
import axios from 'axios';
import { HandlerSlot, ResSlots } from '../types/ce-api/Slots';
import { YoyakuApiUrl } from '../conf/config';

export const useSearchSlot = () => {
  const [resSlotInfo, setResSlotInfo] = useState<ResSlots>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const searchSlot = useCallback(<T extends HandlerSlot>(value: T, mode: number) => {
    setError(false);
    setLoading(true);

    if (typeof value == `object`) {
      let req: HandlerSlot = {
        slot_id: '',
        group_id: '',
        slot_title: '',
        app_uid: '',
        app_label: '',
        start_datetime: '',
        end_datetime: '',
        alert_to1: '',
        alert_to2: '',
        sametime_slot_num: -1,
        rgb: '',
        message: '',
        close_flg: 0,
        the_month: '',
        the_week_monday: '',
        the_day: '',
        src_date: '',
        dst_date: '',
        repeat_num: 0,
      };

      req.group_id = value.group_id;
      req.slot_id = value.slot_id;

      let token: string | null = sessionStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      let api: string = 'slots/search';

      console.log('api=' + api);

      if (token != null) {
        axios
          .post(`${YoyakuApiUrl}/ac/api/v1/${api}`, req, config)
          .then((res) => {
            console.log(res.data);

            setResSlotInfo(res.data);
          })
          .catch((error) => {
            console.log(error);
            setError(true);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, []);

  return { searchSlot, resSlotInfo, loading, error };
};
