import React, { useEffect } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { Box, Button, Tab, Tabs } from '@mui/material';

import { Template } from '../templates/Template';
import { TabPanel } from '../components/utilities/TabPanel';

import { useAccountDevicesList } from '../hooks/useAccountDevicesList';
import { useOneDevices } from '../hooks/useOneDevice';
import { useCheckToken } from '../hooks/useCheckToken';
import { useAllDeviceModels } from '../hooks/useAllDeviceModels';


import { DeviceEdit } from '../components/devices/DeviceEdit';
import { DeviceLanSetting } from '../components/devices/DeviceLanSetting';
import { DeviceStatus } from '../components/devices/DeviceStatus';
import { DeviceMonitoring } from '../components/devices/DeviceMonitoring';

import { AppState } from '../context/AppState';
import { useUserAuth } from '../context/UserAuthProvider';

type Props = RouteComponentProps<{
  device_id: string;
}>;

export const DeviceDetail: React.VFC<Props> = (props) => {
  const device_id = props.match.params.device_id;
  const { state:authState, loading:authLoading } = useUserAuth();
  
  const [value, setValue] = React.useState(0);
  const hisotry = useHistory();
  const { getAccountDevicesList, devices, notExists, loading, error } = useAccountDevicesList();
  const { getDevice, device, loading:oneLoading, error:oneError } = useOneDevices(device_id);
  const { getDeviceModels, deviceModels, loading: deviceModelLoading, error: deviceModelError } = useAllDeviceModels();

  const { checkToken } = useCheckToken();

  const appstate = AppState.getInstance;


  useEffect(() => {
    checkToken();
  }, []);

  const toDeviceIndex = () => {
    hisotry.push('/devices');
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // API
  useEffect(() => {
    getAccountDevicesList(appstate.getAccountId(),1);
  }, [authState.isAuthrized]);
  useEffect(() => {
    getDevice(appstate.getAccountId());
  }, [getDevice]);
  useEffect(() => {
    getDeviceModels();
  }, [getDeviceModels]);

  return (
    // authState.isAuthrized ? (
    <Template title={device ? `デバイス設定（${device?.manage_name}）` : 'デバイス設定'} menuId={0}>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            mt: 2,
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            minWidth: 650,
          }}
        >
          <Tabs value={value} onChange={handleChange} aria-label="device tabs" sx={{ flexGrow: 1 }}>
            <Tab label="デバイス情報" />
            <Tab label="ネットワーク設定" />
            <Tab label="監視 有効 / 無効" />
            <Tab label="デバイス 有効 / 無効" />
          </Tabs>
          <div dir="rtl">
            <Button variant="contained" onClick={toDeviceIndex} color="secondary" size="small">
              一覧に戻る
            </Button>
          </div>
        </Box>
        <TabPanel value={value} index={0}>
          <DeviceEdit device_id={device_id} device={device} devices={devices} deviceModels={deviceModels} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DeviceLanSetting device_id={device_id} device={device} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DeviceMonitoring device_id={device_id} device={device} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <DeviceStatus device_id={device_id} device={device} /> 
        </TabPanel>
      </Box>
    </Template>
  //  ):(<div>loading...</div>)
  );
};
