import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  createTheme,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';

import { SubmitHandler, Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { grey } from '@mui/material/colors';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';

import moment from 'moment';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import getDay from 'date-fns/getDay';
import _ from 'lodash';

import { AppState } from '../../context/AppState';
import { ReqCopySlots } from '../../types/ce-api/Slots';
import { useCopySlots } from '../../hooks/useCopySlots';
import { SettingsRemoteSharp } from '@mui/icons-material';

registerLocale('ja', ja);
ja.options!.weekStartsOn = 1;

// バリデーションルール
const schema = yup.object({
  repeat_num: yup.number().integer('数字を入力してください'),
});
const theme = createTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    subtitle1: {
      fontSize: 14,
      color: grey[800],
    },
  },
});

type Props = {
  copy_open_week: boolean;
  group_id: string;
  src_date: string;
  view_type: number;
  closeCopyDlg: () => void;
};

export const CopyWeekDlgT: React.VFC<Props> = (props: Props) => {
  const { copy_open_week, group_id, src_date, view_type, closeCopyDlg } = props;

  const [groupId, setGroupId] = useState(group_id);
  const [srcDate, setSrcDate] = useState(src_date);
  const [viewType, setViewType] = useState(view_type);

  const [dstDate, setDstDate] = useState('');
  const [repeatNum, setRpeatNum] = useState(0);

  const { copySlots, copyResult, loading: copyLoading, error: copyError } = useCopySlots();

  const appstate = AppState.getInstance;

  useEffect(() => {
    console.log('group_id=' + groupId);
    setGroupId(group_id);
    setSrcDate(src_date);
    setDstDate('');
    setViewType(view_type);
    setDstDate('');
    setRpeatNum(0);
  }, [copy_open_week]);

  const {
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ReqCopySlots>({
    defaultValues: {
      group_id: groupId,
      src_date: src_date,
      dst_date: '',
      repeat_num: 0,
    },
    resolver: yupResolver(schema),
  });

  // -----------------------------------------------------------------------------------
  //  onCopy
  const onCopy: SubmitHandler<ReqCopySlots> = (data) => {
    data.group_id = group_id;
    data.src_date = src_date;
    data.dst_date = dstDate;
    data.repeat_num = repeatNum;

    copySlots(data, 2);
  };
  useEffect(() => {
    if (copyResult === undefined) {
      console.log('undefined');
      return;
    }
    if (copyResult.status === null) {
      console.log('NULL');
      return;
    } else {
      console.log('KOKO:copySlotInfo');
      if (copyResult.status == 0) {
        closeCopyDlg();
      }
    }
  }, [copyResult]);

  const onClose = () => {
    closeCopyDlg();
  };

  // -----------------------------------------------------------------------------------
  //  setDst
  /**
   * JST基準に変換して返す
   * @param {string} dateTimeStr YYYY-MM-DDTHH:mm:00Z
   * @returns {moment.Moment}
   */
  const parseAsMoment = (dateTimeStr: string): moment.Moment => {
    return moment.utc(dateTimeStr, 'YYYY-MM-DDTHH:mm:00Z', 'ja').utcOffset(9);
  };

  /**
   * 日付形式に変換して返す
   * @param {moment.Moment} momentInstance
   * @returns {string}
   */
  const toUtcIso8601str = (momentInstance: moment.Moment): string => {
    return momentInstance.clone().format('YYYY-MM-DD');
  };
  const years = _.range(2022, getYear(new Date()) + 3, 1);
  const months = Array.from(Array(12).keys());

  const onSetDst = (selectedDate: any) => {
    var dst_date = toUtcIso8601str(moment(selectedDate));
    setDstDate(dst_date);
  };

  const isMonday = (date: Date) => {
    const day = getDay(date);
    return day === 1;
  };
  // -----------------------------------------------------------------------------------
  return (
    <Dialog
      open={copy_open_week}
      onClose={onClose}
      fullWidth={true}
      maxWidth={'xs'}
      PaperProps={{ sx: { height: '300' } }}
    >
      <DialogTitle>予約設定のコピー:週単位</DialogTitle>
      <DialogContent>
        <ThemeProvider theme={theme}>
          <Stack spacing={2}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={1}>
                {' '}
              </Grid>
              <Grid item xs={10}>
                <Box sx={{ width: '80%', height: 20 }}></Box>
                <Typography component="div">
                  <Box sx={{ fontWeight: 'bold' }}> 「{src_date}」の週の設定をコピーします </Box>
                </Typography>
                <Box>
                  <Grid container spacing={1} alignItems="center">
                    <Box sx={{ width: '80%', height: 10 }}></Box>
                    <Grid item>
                      <DatePicker
                        locale="ja"
                        filterDate={isMonday}
                        onChange={onSetDst}
                        customInput={
                          <Button variant="outlined">
                            コピー先:<Typography component="div">{dstDate}の週にコピーします</Typography>
                          </Button>
                        }
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div>
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                              前月へ移動
                            </button>
                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) => changeYear(Number(value))}
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}年
                                </option>
                              ))}
                            </select>
                            <select
                              value={getMonth(date)}
                              onChange={({ target: { value } }) => changeMonth(Number(value))}
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option + 1}月
                                </option>
                              ))}
                            </select>
                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                              次月へ移動
                            </button>
                          </div>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ width: '80%', height: 20 }}> </Box>
                <Controller
                  render={({ field }) => (
                    <TextField
                      label="繰り返し*"
                      value={repeatNum}
                      onChange={(e) => {
                        setRpeatNum(Number(e.target.value));
                      }}
                      error={'repeat_num' in errors}
                      helperText={errors.repeat_num?.message}
                      size="small"
                    />
                  )}
                  control={control}
                  name="repeat_num"
                />
                <Box sx={{ width: '80%', height: 20 }}> </Box>
                <div dir="rtl">
                  <Button onClick={handleSubmit(onCopy)} variant="contained">
                    <Typography component="div">
                      <Box sx={{ fontWeight: 'bold' }}> コピー </Box>
                    </Typography>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={1}>
                {' '}
              </Grid>
            </Grid>
            <Box sx={{ width: '80%', height: 200 }}> </Box>
          </Stack>
        </ThemeProvider>
      </DialogContent>
    </Dialog>
  );
};
