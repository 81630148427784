import React, { useState, memo, useEffect } from 'react';
import { useCheckToken } from '../hooks/useCheckToken';
import { Template } from '../templates/Template';
import { useHistory } from 'react-router-dom';

import { AppState } from '../context/AppState';
import { DeviceList } from '../components/devices/DevicesList';

import { Box, Button } from '@mui/material';
import { useUserAuth } from '../context/UserAuthProvider';

export const DeviceIndex: React.VFC = memo(() => {
  const { state:authState, loading:authLoading } = useUserAuth();
  
  const appstate = AppState.getInstance;
  const history = useHistory();

  const { checkToken } = useCheckToken();
  useEffect(() => {
    checkToken(); 
  }, []);
  

  const toDeviceAdd = () => {
    history.push('/devices/add');
  };


  return (
    // authState.isAuthrized ? (
      <Template title={'顔認証デバイス一覧'} menuId={0}>
        <Box sx={{ width: '100%' }}>
          <div dir="rtl">
            <Button variant="contained" sx={{ my: 2 }} onClick={toDeviceAdd}>
              デバイス登録
            </Button>
          </div>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <DeviceList getmode={9} account_id={appstate.getAccountId()} />
        </Box>
      </Template>
    // ):(<div>loading...</div>)
  );
});
