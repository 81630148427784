import React from 'react';
import axios from 'axios';
import { ApiUrl } from '../conf/config';

export const useDeleteGroup = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const deleteGroup = (account_id:string,id: string) => {
    if(account_id != undefined || account_id != ""){
      let token:string|null = sessionStorage.getItem('token');
     const config = {
       headers: { Authorization: `Bearer ${token}` }
     };

      setError(false);
      setLoading(true);

      axios
        .delete(`${ApiUrl}/ac/api/v1/groups/${account_id}/${id}`, config)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.error(error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    };
  }
  return { deleteGroup, loading, error };
};
