export class AppState {
  private static _instance: AppState;
  private constructor() {}

  private accountId: string = '';
  private accountName: string = '';
  private accountRole: number = 0;
  private isLogin: boolean = false;
  private userId: string = '';

  public static get getInstance(): AppState {
    // instanceがなければ生成
    if (!this._instance) {
      console.log('Generate AppState...');
      this._instance = new AppState();
      this._instance.accountId = '';
      this._instance.accountName = '';
      this._instance.userId = '';
    }

    // 自身が持つインスタンスを返す
    return this._instance;
  }

  setAccountId = (id: string) => {
    this.accountId = id;
  };
  setAccountName = (name: string) => {
    this.accountName = name;
  };
  setAccountRole = (role: number) => {
    this.accountRole = role;
  };
  setIsLogin = (islogin: boolean) => {
    this.isLogin = islogin;
  };
  setUserId = (user_id: string) => {
    this.userId = user_id;
  };
  getAccountId = (): string => {
    return this.accountId;
  };
  getAccountName = (): string => {
    return this.accountName;
  };
  getAccountRole = (): number => {
    return this.accountRole;
  };
  getIsLogin = (): boolean => {
    return this.isLogin;
  };
  getUserId = (): string => {
    return this.userId;
  };
}
