import React from 'react';
import axios from 'axios';
import { ApiUrl } from '../conf/config';
import { ReqAcDeleteDevice } from '../types/ce-api/Devices';

export const useDeleteDevice = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const deleteDevice = (account_id:string,device_id: string) => {
    setError(false);
    setLoading(true);
    if(account_id != undefined || account_id != ""){
      let token:string|null = sessionStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      axios
        .delete(`${ApiUrl}/ac/api/v1/devices/${account_id}/${device_id}`,config)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.error(error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
      };
    }
  return { deleteDevice, loading, error };
};
