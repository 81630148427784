import React, { useState, useEffect } from 'react';

// material ui component
import { Box, FormControl, InputLabel, MenuItem, Stack, Select } from '@mui/material';

import { AppState } from '../context/AppState';
import { Template } from '../templates/Template';
import { SlotCalendar } from '../components/yoyaku/SlotCalendar';
import { useCheckToken } from '../hooks/useCheckToken';
import { useAccountGroups } from '../hooks/useAccountGroups';

export const SlotSetting: React.VFC = () => {
  const appstate = AppState.getInstance;
  const { getAccountGroups, groups } = useAccountGroups();
  const { checkTokenYoyaku } = useCheckToken();

  const [groupId, setGroupId] = useState('');
  // -----------------------------------------------------------------------------------
  // initial set.
  useEffect(() => {
    checkTokenYoyaku();
  }, []);
  useEffect(() => {
    getAccountGroups(appstate.getAccountId());
  }, [getAccountGroups]);

  return (
    <Template title={'予約設定'} menuId={2}>
      <Box sx={{ width: '80%', height: 10 }}></Box>
      <Box maxWidth="sm">
        <Stack spacing={2}>
          <FormControl fullWidth size="small">
            <InputLabel id="select-grp-label">グループを選択</InputLabel>
            <Select
              defaultValue="0"
              id="select-grp"
              label="グループを選択"
              onChange={(e) => setGroupId(e.target.value)}
            >
              <MenuItem key={0} value={0}>
                グループを選択してください。
              </MenuItem>
              {groups && groups.length > 0 ? (
                groups.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={-1} value={-1}>
                  グループがありません。グループを作成してください。
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Stack>
      </Box>
      <Box sx={{ width: '80%', height: 20 }}></Box>
      <Box sx={{ width: '80%' }}>
        <SlotCalendar groupId={groupId} />
      </Box>
      <Box sx={{ width: '80%', height: 20 }}></Box>
    </Template>
  );
};
