import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { ApiUrl } from '../conf/config';

import { ReqSilentLogin, ResLogin } from '../types/ce-api/auth';
import { AppState } from '../context/AppState';

export const useSilentLogin = () => {
  const [ resSL   , setResSL   ] = useState<ResLogin>();
  const [ loading , setLoading ] = useState(false);
  const [ error   , setError   ] = useState(false);
  const history = useHistory();

  const silentLogin = useCallback((body: ReqSilentLogin) => {

    const appstate = AppState.getInstance;

    setError(false);
    setLoading(true);

    axios
      .post(`${ApiUrl}/account/silentlogin`, body)
      .then((res) => {
          // tokenを保存
          sessionStorage.setItem('token', res.data.token);

          //ここでユーザ情報セットして/に遷移する
          if (res.data.account_id === null) {
            history.push({ pathname:'/login'});
          }else{
            console.log("ok");
            setResSL(res.data);
          }
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { silentLogin, resSL, loading, error };
};
