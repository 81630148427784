import React from 'react';
import axios from 'axios';
import { ReqAcAddDevice } from '../types/ce-api/Devices';
import { ApiUrl } from '../conf/config';

export const useAddDevice = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const addDevice = (account_id:string,device: ReqAcAddDevice) => {

    if(account_id != "" || account_id != undefined){
      let token:string|null = sessionStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      device.account_id = account_id;
      setError(false);
      setLoading(true);

      axios
        .post(`${ApiUrl}/ac/api/v1/devices`, device,config)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.error(error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return { addDevice, loading, error };
};
