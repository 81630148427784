import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthProvider';

export const useLogout = () => {

  const history = useHistory();
  const { state:authState, loading:authLoading } = useUserAuth();
  
  const logout = useCallback(() => {
    sessionStorage.removeItem('location');
    sessionStorage.removeItem('token');
    history.push('/login');
  }, []);

  return { logout };
};
