import React, { useState, useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// material UI components
import { Box, Stack, Button, TextField, Autocomplete, Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// custom
import { useOneGroup } from '../../hooks/useOneGroup';

import { useAccountDevicesShort } from '../../hooks/useAccountDevicesShort';
import { ResAccountDevicesShort } from '../../types/ce-api/Devices';

import { useEditGroup } from '../../hooks/useEditGroup';
import { ReqEditGroup, ReqEditGroupFrom } from '../../types/ce-api/Groups';

import { AppState } from '../../context/AppState';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// バリデーションルール
const schema = yup.object({
  name: yup.string().required('必須項目です'),
});

type Props = {
  id: string;
};

// React Component
export const GroupInfo: React.VFC<Props> = memo((props) => {
  const { id } = props;
  const appstate = AppState.getInstance;

  const history = useHistory();
  const [deviceList, setDeviceList] = useState<Array<ResAccountDevicesShort>>([]);
  const [deviceNotSelected, setDeviceNotSelected] = useState<Array<ResAccountDevicesShort>>([]);

  // custom hooks
  const { getGroup, group } = useOneGroup(id);
  const { getAccountDevicesShort, devices } = useAccountDevicesShort();
  const { editGroup, loading, error } = useEditGroup();

  // react hook form
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ReqEditGroupFrom>({
    defaultValues: {
      account_id: appstate.getAccountId(),
      name: group.name,
      memo: group.memo,
      devices: group.devices,
    },
    resolver: yupResolver(schema),
  });

  // デバイス一覧ページへ
  const toGroupIndex = () => {
    history.push('/groups');
  };

  const filterDevice = (allDevices: Array<ResAccountDevicesShort>, listDevices: Array<ResAccountDevicesShort>) => {
    let notExistDevice: Array<ResAccountDevicesShort> = [];

    notExistDevice = allDevices.filter((device) => {
      for (let v of listDevices) {
        if (v.id === device.id) {
          return false;
        }
      }
      return true;
    });

    setDeviceNotSelected(notExistDevice);
  };

  // デバイス登録
  const sendGroup: SubmitHandler<ReqEditGroupFrom> = (data) => {
    let reqEditGroupDevices: string[] = [];
    for (let v of deviceList) {
      reqEditGroupDevices = [...reqEditGroupDevices, v.id];
    }

    let reqEditGroup: ReqEditGroup = {
      name: data.name,
      memo: data.memo,
      device_ids: reqEditGroupDevices,
    };

    console.log(reqEditGroup);
    editGroup(appstate.getAccountId(), id, reqEditGroup);
    if (!error && !loading) {
      alert('グループを編集しました。');
      toGroupIndex();
    }
  };

  // API
  useEffect(() => {
    getGroup(appstate.getAccountId(), id);
  }, []);
  useEffect(() => {
    setValue('account_id', appstate.getAccountId());
    setValue('name', group.name);
    setValue('memo', group.memo);
    setDeviceList(group.devices);
    getAccountDevicesShort(appstate.getAccountId());
  }, [group]);
  useEffect(() => {
    filterDevice(devices, deviceList);
  }, [devices]);

  console.log('device list', deviceList);

  return (
    <Box maxWidth="sm" sx={{ py: 5 }}>
      <Stack spacing={2} sx={{ mb: 5 }}>
        <Controller
          render={({ field }) => (
            <TextField
              required
              label="グループ名"
              {...field}
              error={'name' in errors}
              helperText={errors.name?.message}
              size="small"
            />
          )}
          control={control}
          name="name"
          defaultValue={group.name}
        />
        <Controller
          render={({ field }) => (
            <TextField
              required
              label="メモ"
              {...field}
              error={'memo' in errors}
              helperText={errors.memo?.message}
              size="small"
            />
          )}
          control={control}
          name="memo"
          defaultValue={group.memo}
        />
        <Controller
          render={({ field }) => (
            <Autocomplete
              multiple
              {...field}
              id="checkboxes-tags-demo"
              value={deviceList}
              onChange={(event, newValue) => {
                setDeviceList([...newValue]);
                filterDevice(devices, [...newValue]);
              }}
              options={[...deviceList, ...deviceNotSelected].sort(function (a, b) {
                return a.id < b.id ? -1 : 1; //昇順ソート
              })}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.name}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} label="デバイス" placeholder="デバイスを選択" size="small" />
              )}
            />
          )}
          control={control}
          name="devices"
          defaultValue={deviceList}
        />
      </Stack>
      <div dir="rtl">
        <Button variant="contained" sx={{ my: 2, ml: 2 }} onClick={handleSubmit(sendGroup)}>
          編集
        </Button>
        <Button variant="contained" sx={{ my: 2 }} color="secondary" onClick={toGroupIndex}>
          キャンセル
        </Button>
      </div>
    </Box>
  );
});
