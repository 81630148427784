import { Box } from '@mui/material';
import { createContext, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppState } from '../context/AppState';
import { useCheckToken } from '../hooks/useCheckToken';
import { useSilentLogin } from '../hooks/useSilentLogin';
import { ReqSilentLogin } from '../types/ce-api/auth';

// data define
type StateType = {
  isAuthrized: boolean;
};

type ContextType = {
  state: StateType;
  loading: boolean;
};

const initialState = { isAuthrized: false };

const UserAuthContext = createContext({} as ContextType);

export function useUserAuth(): ContextType {
  return useContext(UserAuthContext);
}

export const UserAuthProvider = ({ children }: any) => {
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const appstate = AppState.getInstance;
  const { silentLogin, resSL, loading: silentLoading, error: silentError } = useSilentLogin();

  const silent = async () => {
    //silentLogin
    const req: ReqSilentLogin = {
      token: sessionStorage.getItem('token')!,
    };
    await silentLogin(req);
  };

  useEffect(() => {
    console.log('user auth');
    setLoading(true);
    if (appstate.getAccountId() == '' || appstate.getAccountId() == undefined) {
      if (sessionStorage.getItem('token') !== null && appstate.getIsLogin() === false) {
        console.log('saisyo');
        silent();
      } else {
        if (sessionStorage.getItem('token') === null) {
          // console.log("koko");
          // setLoading(false);
          // history.replace('/login');
          setLoading(false);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (resSL === undefined) {
      console.log('undefined');
      return;
    }
    if (resSL.account_id === null) {
      console.log('NULL');
      return;
    } else {
      console.log('SilentLogin end.');
      appstate.setAccountId(resSL.account_id);
      appstate.setAccountName(resSL.name);
      appstate.setAccountRole(resSL.role_id);
      appstate.setIsLogin(true);
      appstate.setUserId(resSL.user_id);
      setState({ isAuthrized: true });
      setLoading(false);
    }
  }, [resSL]);

  const values = {
    state,
    loading,
  };

  if (loading) {
    return <div> loading...</div>;
  }
  return <UserAuthContext.Provider value={values}>{!loading && children}</UserAuthContext.Provider>;
};
