import { useState, useCallback } from 'react';
import axios from 'axios';
import { ReqAcDeviceInfo,ResAcDeviceInfo } from '../types/ce-api/Devices';
import { ApiUrl } from '../conf/config';

export const useOneDevices = (id: string) => {
  const [device, setDevice] = useState<ResAcDeviceInfo>({
    id: '',
    account_id: '',
    device_model_id: '',
    type: 0,
    identified_code: '',
    activation: 0,
    status: 0,
    name: '',
    manage_name: '',
    lan_setting_enable: 0,
    lan_dhcp: 0,
    lan_ip: '',
    lan_mask: '',
    lan_gateway: '',
    lan_dns1: '',
    lan_dns2: '',
    login_id: '',
    login_password: '',
    is_connect: 0,
    is_monitoring: 0,
    parent_device_id: 0,
    group_id:'',
    group_name:'',
    group_memo:''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getDevice = useCallback((account_id:string) => {
    let req:ReqAcDeviceInfo = {account_id:account_id };
    let token:string|null = sessionStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };


    setError(false);
    setLoading(true);

    axios
      .post(`${ApiUrl}/ac/api/v1/devices/${id}`,req,config)
      .then((res) => {
        console.log(res.data);
        setDevice(res.data);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { getDevice, device, loading, error };
};
