import React, { useState, memo } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  FormControl,
  IconButton,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import { green, red, grey } from '@mui/material/colors';

import { TablePaginationActions } from '../utilities/TablePagination';
import { useDeleteDevice } from '../../hooks/useDeleteDevice';

import { ResAcDeviceInfo,DevicesList } from '../../types/ce-api/Devices';
import { useAccountDevicesList } from '../../hooks/useAccountDevicesList';
import { useFormattingDevices } from '../../hooks/useFormattingDevices';

import { AppState } from '../../context/AppState';


type Props = {
  getmode: number;
  account_id: string;
};

export const DeviceList: React.VFC<Props> = memo((props) => {
  const { getmode, account_id } = props;
  const hisotry = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  const { getAccountDevicesList, devices, notExists, loading, error } = useAccountDevicesList();
  const { formatDevices, formattedDevices } = useFormattingDevices(devices);
  const { deleteDevice, loading:delLoading, error:delError } = useDeleteDevice();
  
  const appstate = AppState.getInstance;
  
  // get account's device list
  React.useEffect(() => {
    getAccountDevicesList(appstate.getAccountId(),getmode);
  }, [getAccountDevicesList]);
  // device data formatting for display.
  React.useEffect(() => {
    formatDevices();
  }, [devices]);


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - devices.length) : 0;

  const toDetailPage = (id: string) => {
    hisotry.push(`/devices/details/${id}`);
  };

  const onClickDeleteDevice = (device_id: string) => {
     const result = window.confirm('デバイスを削除してもよろしいですか？');
     if (result) {
       deleteDevice(appstate.getAccountId(),device_id);
       if (!error && !loading) {
         alert('デバイスを削除しました。');
         getAccountDevicesList(appstate.getAccountId(),getmode);
       }
     }
  };

  const searchDevice = () => {
    console.log('search');
  };
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>

      <TableContainer component={Paper} sx={{ boxShadow: '1px 1px 10px #bbb', my: 5 }}>
        <Table
          sx={{
            minWidth: 650,
            boxShadow: '1px 1px 6px #ddd',
          }}
          aria-label="accounts table"
        >
          <TableHead>
            <TableRow>
             <TableCell align="center" sx={{ px: 0 }}>グループ名</TableCell>
              <TableCell>デバイス名</TableCell>
              <TableCell>デバイスタイプ</TableCell>
              <TableCell align="center" sx={{ px: 0 }}>
                ステータス
              </TableCell>
              <TableCell align="center">
                  <IconButton
                          color="primary"
                          aria-label="settings"
                          component="span"
                          sx={{ ml: 1 }}
                          onClick={() => {
                            getAccountDevicesList(appstate.getAccountId(),getmode);
                          }}
                        >
                          <RefreshIcon sx={{ color: '#757575' }}/>
                        </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0 ? formattedDevices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : formattedDevices).map(
              (device) => (
                <TableRow key={device.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center" sx={{ px: 0 }}>
                    {device.group_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {device.manage_name}
                  </TableCell>
                  <TableCell>{device.type_name}</TableCell>
                  <TableCell align="center" sx={{ px: 0 }}>
                    {device.status === 0 || device.activation === 0 || device.is_monitoring === 0 ? (
                      <DoDisturbIcon sx={{ color: grey[500] }} />
                    ) : device.is_connect === 1 ? (
                      <CheckCircleOutlineIcon sx={{ color: green[600] }} />
                    ) : (
                      <CancelOutlinedIcon sx={{ color: red[600] }} />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      aria-label="settings"
                      component="span"
                      sx={{ ml: 1 }}
                      onClick={() => {
                        toDetailPage(device.id);
                      }}
                    >
                      <SettingsIcon sx={{ color: '#757575' }} />
                    </IconButton>
                    <IconButton
                      color="primary"
                      aria-label="delete"
                      component="span"
                      sx={{ ml: 1 }}
                      onClick={() => {
                        onClickDeleteDevice(device.id);
                      }}
                    >
                      <DeleteIcon sx={{ color: '#757575' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 73 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={7}
                count={devices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
});
