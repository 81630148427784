import { useState, useCallback } from 'react';
import axios from 'axios';
import { ResGetOneGroup } from '../types/ce-api/Groups';
import { ApiUrl } from '../conf/config';

export const useOneGroup = (id: string) => {
  const [group, setGroup] = useState<ResGetOneGroup>({
    id: id,
    account_id: '',
    account_name: '',
    name: '',
    memo: '',
    devices: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getGroup = useCallback((account_id:string,id:string) => {
    if(account_id != undefined || account_id != ""){
      let token:string|null = sessionStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      setError(false);
      setLoading(true);

      axios
        .get(`${ApiUrl}/ac/api/v1/groups/${account_id}/${id}`,config)
        .then((res) => {
          console.log(res.data);
          setGroup(res.data);
        })
        .catch((error) => {
          console.error(error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return { getGroup, group, loading, error };
};
