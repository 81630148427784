import { useCallback, useEffect, useState } from 'react';

// Material UI components
import {
  Box,
  Button,
  Checkbox,
  createTheme,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { grey } from '@mui/material/colors';

import { AppState } from '../../context/AppState';
import { AppUserType } from '../../types/ce-api/Yoyaku';

const theme = createTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    subtitle1: {
      fontSize: 14,
      color: grey[800],
    },
  },
});

type Props = {
  open_yoyaku: boolean;
  edit_id: string;
  group_id: string;
  dateStr: string;
  start: string;
  end: string;
  app_users: Array<AppUserType>;
  mode: number;
  closeYoyakuDlg: () => void;
};

export const YoyakuDlg: React.VFC<Props> = (props: Props) => {
  const { open_yoyaku, edit_id, group_id, dateStr, start, end, app_users, mode, closeYoyakuDlg } = props;
  const [groupId, setGroupId] = useState(group_id);
  const [editId, setEditId] = useState(edit_id);
  const [startText, setStartText] = useState(start);
  const [endText, setEndText] = useState(end);
  const [appUsers, setAppUsers] = useState<Array<AppUserType>>(app_users);
  const [dlgmode, setDlgMode] = useState(mode);

  const appstate = AppState.getInstance;

  useEffect(() => {
    console.log('dlg mode=' + mode);
    if (mode == 1) {
      console.log('group_id=' + groupId);

      setGroupId(group_id);
      setStartText(start);
      setEndText(end);
      setAppUsers(app_users);
      setDlgMode(mode);
    } else if (mode == 2) {
      console.log('mode=2');
      console.log('group_id=' + groupId);
      setEditId(edit_id);
      setGroupId(group_id);
      setStartText(start);
      setEndText(end);
      setAppUsers(app_users);
      setDlgMode(mode);
    }
  }, [mode]);

  const onClose = () => {
    closeYoyakuDlg();
  };

  return (
    <Dialog open={open_yoyaku} onClose={onClose} fullWidth={true} maxWidth={'xs'}>
      <DialogTitle>予約情報：</DialogTitle>
      <DialogContent>
        <ThemeProvider theme={theme}>
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                {' '}
              </Grid>
              <Grid item xs={8}>
                <Box sx={{ width: '80%', height: 20 }}></Box>
                <Box>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <TextField
                        label="開始時間*"
                        value={startText}
                        onChange={(e) => {
                          setStartText(e.target.value);
                        }}
                        size="small"
                        disabled
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        label="終了時間*"
                        value={endText}
                        onChange={(e) => {
                          setEndText(e.target.value);
                        }}
                        size="small"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ width: '80%', height: 20 }}> </Box>
              </Grid>
              <Grid item xs={1}>
                {' '}
              </Grid>
            </Grid>
            <Box sx={{ width: '80%', height: 10 }}> </Box>
          </Stack>
        </ThemeProvider>
      </DialogContent>
    </Dialog>
  );
};
