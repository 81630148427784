import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import { Router } from './router/Router';
import { Theme } from './conf/theme';
import { UserAuthProvider, useUserAuth } from './context/UserAuthProvider';
import AuthProtectRoute from './context/AuthProtectRoute';

export const App: React.VFC = () => {
  const { state } = useUserAuth();
  return (
    <UserAuthProvider>
      <ThemeProvider theme={Theme}>
        <BrowserRouter>
          <CssBaseline />
          <AuthProtectRoute>
          <Router />
          </AuthProtectRoute>
        </BrowserRouter>
      </ThemeProvider>
    </UserAuthProvider>
  );
};
