import React from 'react';
import axios from 'axios';
import { ReqEditGroup } from '../types/ce-api/Groups';
import { ApiUrl } from '../conf/config';

export const useEditGroup = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const editGroup = (account_id:string,id: string, group: ReqEditGroup) => {
    if(account_id != undefined || account_id != 0){
      let token:string|null = sessionStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      setError(false);
      setLoading(true);

      axios
        .put(`${ApiUrl}/ac/api/v1/groups/${account_id}/${id}`, group,config)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.error(error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return { editGroup, loading, error };
};
