import React, { useState, useEffect } from 'react';
import { useCheckToken } from '../hooks/useCheckToken';
import moment from 'moment';

// Material UI components
import { Box, FormControl, InputLabel, MenuItem, Stack, Select } from '@mui/material';

import { Template } from '../templates/Template';
import { AppState } from '../context/AppState';
import { YoyakuCalendar } from '../components/yoyaku/YoyakuCalendar';
import { useAccountGroups } from '../hooks/useAccountGroups';

export const YoyakuView: React.VFC = () => {
  const appstate = AppState.getInstance;
  const { getAccountGroups, groups } = useAccountGroups();
  const { checkTokenYoyaku } = useCheckToken();

  const [groupId, setGroupId] = useState('');
  // -----------------------------------------------------------------------------------
  // initial set.
  useEffect(() => {
    checkTokenYoyaku();
  }, []);
  useEffect(() => {
    getAccountGroups(appstate.getAccountId());
  }, [getAccountGroups]);

  return (
    <Template title={'予約状況確認'} menuId={3}>
      <Box sx={{ width: '80%', height: 10 }}></Box>
      <Box maxWidth="sm">
        <Stack spacing={2}>
          <FormControl fullWidth size="small">
            <InputLabel id="select-grp-label">施設を選択</InputLabel>
            <Select defaultValue="0" id="select-grp" label="施設を選択" onChange={(e) => setGroupId(e.target.value)}>
              <MenuItem key={0} value={0}>
                施設を選択してください。
              </MenuItem>
              {groups && groups.length > 0 ? (
                groups.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={-1} value={-1}>
                  施設がありません。施設設定をしてください。
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Stack>
      </Box>
      <Box sx={{ width: '80%', height: 20 }}></Box>
      <Box sx={{ width: '80%' }}>
        <YoyakuCalendar groupId={groupId} />
      </Box>
      <Box sx={{ width: '80%', height: 20 }}></Box>
    </Template>
  );
};
