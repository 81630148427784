import { useState, useCallback } from 'react';
import axios from 'axios';
import { ReqAcGetDeviceList,ResAcDeviceInfo } from '../types/ce-api/Devices';
import { ApiUrl } from '../conf/config';

export const useAccountDevicesList = () => {
  const [devices, setDevices]     = useState<Array<ResAcDeviceInfo>>([]);
  const [notExists, setNotExists] = useState(false);
  const [loading, setLoading]     = useState(false);
  const [error, setError]         = useState(false);

  const getAccountDevicesList = useCallback((account_id:string,getmode:number) => {
    setError(false);
    setLoading(true);
    if(account_id != undefined || account_id != 0){
      let req:ReqAcGetDeviceList = {account_id:account_id , get_mode:getmode };
      let token:string|null = sessionStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      if(token != null){
          axios
            .post(`${ApiUrl}/ac/api/v1/devices/list`,req,config)
            .then((res) => {
              console.log(res.data.devices);
              if (res.data.devices === null) {
                setNotExists(true);
                setDevices([]);
              } else {
                setDevices(res.data.devices);
              }
            })
            .catch((error) => {
              console.log(error);
              setError(true);
            })
            .finally(() => {
              setLoading(false);
            });
      }
    }
  }, []);

  return { getAccountDevicesList, devices, notExists, loading, error };
};

