import React, { useState, memo, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { ReqAcEditDeviceStatus, ResAcDeviceInfo } from '../../types/ce-api/Devices';
import { useEditDeviceStatus } from '../../hooks/useEditDeviceStatus';

import { AppState } from '../../context/AppState';

type Props = {
  device_id: string;
  device: ResAcDeviceInfo;
};

export const DeviceStatus: React.VFC<Props> = memo((props) => {
  const { device_id , device } = props;
  const [status, setStatus] = useState<number>();
  const { editDeviceStatus, loading, error } = useEditDeviceStatus();
  const appstate = AppState.getInstance;

  const onClickEditDeviceStatus = (status: number) => {
    if(appstate.getAccountId() != undefined || appstate.getAccountId() != ""){
      const deviceStatus: ReqAcEditDeviceStatus = {
          account_id : appstate.getAccountId(),
          status: status,
      };
      editDeviceStatus(device_id, deviceStatus);
      if (!error && !loading) {
        alert('デバイス情報を編集しました。');
        setStatus(status);
      }
    }
  };

  useEffect(() => {
    setStatus(device.status);
  }, [device]);

  return (
    <Box maxWidth="sm" sx={{ py: 5, display: 'flex' }}>
      {status === 1 ? (
        <>
          <Typography align="left" component="div">
            <Box sx={{ fontSize: 'h6.fontSize' }}>デバイスの状態： 有効</Box>
          </Typography>
          <Button
            variant="contained"
            sx={{ ml: 5, px: 3 }}
            color="error"
            onClick={() => {
              onClickEditDeviceStatus(0);
            }}
          >
            無効化する
          </Button>
        </>
      ) : (
        <>
          <Typography align="left" component="div">
            <Box sx={{ fontSize: 'h6.fontSize' }}>デバイスの状態： 無効</Box>
          </Typography>
          <Button
            variant="contained"
            sx={{ ml: 5, px: 3 }}
            onClick={() => {
              onClickEditDeviceStatus(1);
            }}
          >
            有効化する
          </Button>
        </>
      )}
    </Box>
  );
});
