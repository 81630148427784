import { useUserAuth } from './UserAuthProvider';

import { FC } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { AppState } from '../context/AppState';

const AuthProtectRoute: FC = ({ children }) => {
  const appstate = AppState.getInstance;
  const { state:authState, loading:authLoading } = useUserAuth();
  const location = useLocation();

  //logoutした
  if(sessionStorage.getItem('location') === null){
      location.pathname = "/login";
  }

  if(appstate.getIsLogin()  === true && sessionStorage.getItem('location') !== null && location.pathname == "/login"){
      location.pathname = sessionStorage.getItem('location')!;
  }
  
  console.log("authProtect=="+appstate.getIsLogin() );
  console.log("location="+location.pathname);

  sessionStorage.setItem('location',location.pathname);
  if(appstate.getIsLogin() === false && location.pathname == "/login"){
      return <>{children}</>;
  }

  return appstate.getIsLogin() ?  <> {children}</> : <Redirect to={{
    pathname: '/login',
    state: { from: location },
  }} /> ;
};

export default AuthProtectRoute;