import React from 'react';
import axios from 'axios';
import { YoyakuApiUrl } from '../conf/config';
import { ReqAddEditGetReservableInfo } from '../types/ce-api/Yoyaku';

export const useEditReservableInfo = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const editReservableInfo = async (id: string, reservableInfo: ReqAddEditGetReservableInfo) => {
    const token: string | null = sessionStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    setError(false);
    setLoading(true);

    try {
      const res = await axios.put(`${YoyakuApiUrl}/ac/api/v1/rsrvinfos/${id}`, reservableInfo, config);
      console.log(res.data);
      if (res.status === 200) {
        alert('予約設定を保存しました。');
      }
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return { editReservableInfo, loading, error };
};
