import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Stack,
  TextField,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

import { Template } from '../templates/Template';
import { useAddDevice } from '../hooks/useAddDevice';
import { ReqAcAddDevice } from '../types/ce-api/Devices';
import { useAllDeviceModels } from '../hooks/useAllDeviceModels';
import { useAccountDevicesList } from '../hooks/useAccountDevicesList';
import { useCheckToken } from '../hooks/useCheckToken';
import { AppState } from '../context/AppState';

const device_types = [
  { id: 1, name: 'Edge Extension' },
  { id: 2, name: 'Pad' },
  { id: 3, name: 'AIBOX' },
  { id: 4, name: 'thermo' },
];

// バリデーションルール
const schema = yup.object({
  device_model_id: yup.number().typeError('選択してください'),
  type: yup.number().typeError('選択してください'),
  identified_code: yup.string().required('必須項目です'),
  name: yup.string().required('必須項目です'),
  manage_name: yup.string().required('必須項目です'),
  lan_setting_enable: yup.number().typeError('選択してください'),
  lan_dhcp: yup.number(),
  lan_ip: yup.string().required('必須項目です'),
  lan_mask: yup.string(),
  lan_gateway: yup.string(),
  lan_dns1: yup.string(),
  lan_dns2: yup.string(),
  login_id: yup.string(),
  login_password: yup.string(),
  parent_device_id: yup.mixed().notRequired(),
});

export const DeviceRegister: React.VFC = () => {
  const appstate = AppState.getInstance;
  
  const history = useHistory();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ReqAcAddDevice>({
    defaultValues: {
      account_id: appstate.getAccountId(),
    },
    resolver: yupResolver(schema),
  });
  const { getAccountDevicesList, devices, notExists, loading, error } = useAccountDevicesList();
  const { getDeviceModels, deviceModels, loading: deviceModelLoading, error: deviceModelError } = useAllDeviceModels();
  const { addDevice, loading:addDeviceloading, error:addDeviceError } = useAddDevice();
  const { checkToken } = useCheckToken();
  
  const watchDeviceType = watch('type');
  
  useEffect(() => {
    checkToken();
  }, []);

  // デバイス一覧ページへ
  const toDeviceIndex = () => {
    history.push('/devices');
  };

  // デバイス登録
  const deviceRegister: SubmitHandler<ReqAcAddDevice> = (data) => {
    console.log('register');
    console.log(data);
    addDevice(appstate.getAccountId(),data);
    if (!error && !loading) {
      alert('デバイスを登録しました。');
      toDeviceIndex();
    }
  };

  // API
  useEffect(() => {
    getAccountDevicesList(appstate.getAccountId(),1);
  }, [getAccountDevicesList]);
  useEffect(() => {
    getDeviceModels();
  }, [getDeviceModels]);

  return (
    <Template title="デバイス登録" menuId={0}>
      <Box maxWidth="sm" sx={{ py: 5, px: 3 }}>
        <Stack spacing={2} sx={{ mb: 5 }}>
          <Typography align="left" component="div">
            <Box sx={{ fontSize: 'default' }}>デバイス情報</Box>
          </Typography>
          <FormControl fullWidth size="small" required error={'type' in errors}>
            <InputLabel id="demo-simple-select-label">デバイスタイプ</InputLabel>
            <Select id="demo-simple-select" {...register('type')} label="デバイスタイプ*">
              {device_types.length > 0
                ? device_types.map((device_type) => (
                    <MenuItem key={device_type.id} value={device_type.id}>
                      {device_type.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
            <FormHelperText>{errors.type?.message}</FormHelperText>
          </FormControl>
          {watchDeviceType && (
            <>
              <FormControl fullWidth size="small" required error={'device_model_id' in errors}>
                <InputLabel id="demo-simple-select-label">デバイスモデル</InputLabel>
                <Select id="demo-simple-select" {...register('device_model_id')} label="デバイスモデル*">
                  {deviceModels.length > 0
                    ? deviceModels.map(
                        // 選択したデバイスタイプのデバイスモデルのみ表示
                        (deviceModel) =>
                          watchDeviceType === deviceModel.device_type && (
                            <MenuItem key={deviceModel.id} value={deviceModel.id}>
                              {deviceModel.name}
                            </MenuItem>
                          )
                      )
                    : null}
                </Select>
                <FormHelperText>{errors.device_model_id?.message}</FormHelperText>
              </FormControl>

              <TextField
                required
                label="デバイスコード"
                {...register('identified_code')}
                error={'identified_code' in errors}
                helperText={errors.identified_code?.message}
                size="small"
              />
              <TextField
                required
                label="デバイス名(Pad表示用)"
                {...register('name')}
                error={'name' in errors}
                helperText={errors.name?.message}
                size="small"
              />
              <TextField
                required
                label="デバイス名(管理用)"
                {...register('manage_name')}
                error={'manage_name' in errors}
                helperText={errors.manage_name?.message}
                size="small"
              />
              {
                // EEの場合は必要なし
                watchDeviceType === 1 || (
                  <>
                    <TextField
                      required
                      label="ログインID"
                      {...register('login_id')}
                      error={'login_id' in errors}
                      helperText={errors.login_id?.message}
                      size="small"
                    />
                    <TextField
                      required
                      label="パスワード"
                      {...register('login_password')}
                      error={'login_password' in errors}
                      helperText={errors.login_password?.message}
                      size="small"
                    />
                    <FormControl fullWidth size="small" required error={'parent_device_id' in errors}>
                      <InputLabel id="demo-simple-select-label">管理端末</InputLabel>
                      <Select id="demo-simple-select" {...register('parent_device_id')} label="Edge Extension*">
                        {devices.length > 0
                          ? devices.map(
                              (device) =>
                                device.type === 1 &&(
                                  <MenuItem key={device.id} value={device.id}>
                                    {device.manage_name}
                                  </MenuItem>
                                )
                            )
                          : null}
                      </Select>
                      <FormHelperText>{errors.parent_device_id?.message}</FormHelperText>
                    </FormControl>
                  </>
                )
              }
            </>
          )}
        </Stack>
        {watchDeviceType && (
          <Stack spacing={2} sx={{ mb: 5 }}>
            <Typography align="left" component="div">
              <Box sx={{ fontSize: 'default' }}>ネットワーク情報</Box>
            </Typography>
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-simple-select-label">LAN設定変更</InputLabel>
              <Select id="demo-simple-select" {...register('lan_setting_enable')} label="LAN設定変更*">
                <MenuItem value={1}>有効</MenuItem>
                <MenuItem value={0}>無効</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-simple-select-label">DHCP</InputLabel>
              <Select id="demo-simple-select" {...register('lan_dhcp')} label="DHCP*">
                <MenuItem value={1}>有効</MenuItem>
                <MenuItem value={0}>無効</MenuItem>
              </Select>
            </FormControl>
            <TextField required label="IPアドレス" {...register('lan_ip')} size="small" />
            <TextField required label="サブネットマスク" {...register('lan_mask')} size="small" />
            <TextField required label="デフォルトゲートウェイ" {...register('lan_gateway')} size="small" />
            <TextField required label="DNSサーバ1" {...register('lan_dns1')} size="small" />
            <TextField label="DNSサーバ2" {...register('lan_dns2')} size="small" />
          </Stack>
        )}
        <div dir="rtl">
          <Button variant="contained" sx={{ my: 2, ml: 2 }} onClick={handleSubmit(deviceRegister)}>
            登録
          </Button>
          <Button variant="contained" sx={{ my: 2 }} color="secondary" onClick={toDeviceIndex}>
            キャンセル
          </Button>
        </div>
      </Box>
    </Template>
  );
};
