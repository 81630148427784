import { useState, useCallback } from 'react';
import axios from 'axios';
import { YoyakuApiUrl } from '../conf/config';
import { HandlerSlot, ReqGetSlots, ResSlots } from '../types/ce-api/Slots';

export const useGetAccountSlotList = () => {
  const [resGetSlot, setResGetSlot] = useState<ResSlots>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getAccountSlotList = useCallback(<T extends HandlerSlot | ReqGetSlots>(value: T, type: number) => {
    console.log('getAccountSlotList');
    setError(false);
    setLoading(true);

    if (typeof value == `object`) {
      var api: string = '';
      let req: HandlerSlot = {
        slot_id: '',
        group_id: '',
        slot_title: '',
        app_uid: '',
        app_label: '',
        start_datetime: '',
        end_datetime: '',
        alert_to1: '',
        alert_to2: '',
        sametime_slot_num: 0,
        rgb: '',
        message: '',
        close_flg: 0,
        the_month: '',
        the_week_monday: '',
        the_day: '',
        src_date: '',
        dst_date: '',
        repeat_num: 0,
      };

      if (type == 1) {
        /* month */
        req.group_id = value.group_id;
        req.the_month = value.the_month;
        api = 'themonth';
      }
      if (type == 2) {
        /* week */
        req.group_id = value.group_id;
        req.the_week_monday = value.the_week_monday;
        api = 'theweek';
      }
      if (type == 3) {
        /* day */
        req.group_id = value.group_id;
        req.the_day = value.the_day;
        api = 'theday';
      }

      let token: string | null = sessionStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      if (token != null) {
        console.log('api=' + api);

        axios
          .post(`${YoyakuApiUrl}/ac/api/v1/slots/${api}`, req, config)
          .then((res) => {
            console.log(res.data);

            setResGetSlot(res.data);
          })
          .catch((error) => {
            console.log(error);
            setError(true);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, []);

  return { getAccountSlotList, resGetSlot, loading, error };
};
