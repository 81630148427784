import { useState, useCallback } from 'react';
import axios from 'axios';
import { ReqAcDeviceInfo,ResAccountDevicesShort } from '../types/ce-api/Devices';
import { ApiUrl } from '../conf/config';

export const useAccountDevicesShort = () => {
  const [devices, setDevices] = useState<Array<ResAccountDevicesShort>>([]);
  const [notExists, setNotExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getAccountDevicesShort = useCallback((account_id:string) => {
    if(account_id != undefined || account_id != 0){
      let req:ReqAcDeviceInfo = {account_id:account_id};
      let token:string|null = sessionStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      setError(false);
      setLoading(true);

      axios
        .post(`${ApiUrl}/ac/api/v1/devices/account`,req, config)
        .then((res) => {
          console.log(res.data.devices);
          if (res.data.devices === null) {
            setNotExists(true);
            setDevices([]);
          } else {
            setDevices(res.data.devices);
          }
        })
        .catch((error) => {
          console.log(error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return { getAccountDevicesShort, devices, notExists, loading, error };
};
