import { useState, useCallback } from 'react';
import axios from 'axios';
import { YoyakuApiUrl } from '../conf/config';
import { ReqSearchReserve, ResSearchReserves } from '../types/ce-api/Yoyaku';

export const useGetAccountYoyakuList = () => {
  const [resGetYoyaku, setResGetYoyaku] = useState<ResSearchReserves>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getAccountYoyakuList = useCallback((value: ReqSearchReserve, getType: number) => {
    console.log('getAccountSlotList');
    setError(false);
    setLoading(true);

    if (typeof value == `object`) {
      var api: string = '';
      let req: ReqSearchReserve = {
        group_id: '',
        reserve_id: '',
        app_users: [],
        slot_id: '',
        start_datetime: '',
        end_datetime: '',
        the_day: '',
        the_weekday: 0,
        the_start_time: '',
        the_end_time: '',
      };
      if (getType == 1) {
        /* month,week,day term */
        req.group_id = value.group_id;
        req.start_datetime = value.start_datetime;
        req.end_datetime = value.end_datetime;
        api = 'searchrsrv';
      }
      if (getType == 2) {
        /* 人指定 */
        req.group_id = value.group_id;
        req.start_datetime = value.start_datetime;
        req.end_datetime = value.end_datetime;
        req.app_users = value.app_users;
        api = 'searchrsrv';
      }

      let token: string | null = sessionStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      if (token != null) {
        console.log('api=' + api);

        axios
          .post(`${YoyakuApiUrl}/core/api/v1/${api}`, req, config)
          .then((res) => {
            console.log(res.data);

            setResGetYoyaku(res.data);
          })
          .catch((error) => {
            console.log(error);
            setError(true);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, []);

  return { getAccountYoyakuList, resGetYoyaku, loading, error };
};
