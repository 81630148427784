import { EventInput } from "@fullcalendar/react";

export const ApiUrl = process.env.REACT_APP_CE_API_ENDPOINT || 'http://localhost:18080';
export const YoyakuApiUrl = process.env.REACT_APP_YOYAKU_API_ENDPOINT || 'http://localhost:9099';

let eventGuid = 0;
const todayStr = new Date().toISOString().replace(/T.*$/, "");  // 今日の日付をYYYY-MM-DD形式にする
export const createEventId = () => String(eventGuid++);
export const INITIAL_EVENTS: EventInput[] = [
  {
    id: createEventId(),
    title: "Timed event",
    start: todayStr + "T12:00:00",  // 時刻はTで結ぶ
  },
];