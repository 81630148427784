import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { ApiUrl, YoyakuApiUrl } from '../conf/config';

import { ReqCheckToken, ResCheckToken } from '../types/ce-api/auth';

export const useCheckToken = () => {
  const history = useHistory();
  const [ resCheckToken, setResCheckToken ] = useState<ResCheckToken>();
  const checkToken = useCallback(() => {
    const body: ReqCheckToken = {
      token: sessionStorage.getItem('token'),
    };

    axios
      .post(`${ApiUrl}/account/token`, body)
      .then((res) => {
        setResCheckToken(res.data);
      })
      .catch((error) => {
        console.error(error);
        history.push('/login');
      });
  }, []);

  const checkTokenYoyaku = useCallback(() => {
    const body: ReqCheckToken = {
      token: sessionStorage.getItem('token'),
    };
    console.log("token="+sessionStorage.getItem('token'));

    axios
      .post(`${YoyakuApiUrl}/account/token`, body)
      .then((res) => {
        setResCheckToken(res.data);
      })
      .catch((error) => {
        console.error(error);
        history.push('/login');
      });
  }, []);

  return { checkToken,checkTokenYoyaku,resCheckToken };
};
