import React, { memo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { ReqAcEditDeviceLan, ResAcDeviceInfo } from '../../types/ce-api/Devices';
import { useEditDeviceLan } from '../../hooks/useEditDeviceLan';
import { AppState } from '../../context/AppState';

// バリデーションルール
const schema = yup.object({
  lan_setting_enable: yup.number().typeError('選択してください'),
  lan_dhcp: yup.number(),
  lan_ip: yup.string().required('必須項目です'),
  lan_mask: yup.string(),
  lan_gateway: yup.string(),
  lan_dns1: yup.string(),
  lan_dns2: yup.string(),
});

type Props = {
  device_id: string;
  device: ResAcDeviceInfo;
};

export const DeviceLanSetting: React.VFC<Props> = memo((props) => {
  const { device_id, device } = props;
  const { editDeviceLan, loading, error } = useEditDeviceLan();
  const hisotry = useHistory();
  
  const appstate = AppState.getInstance;

  const {
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ReqAcEditDeviceLan>({
    defaultValues: {
      lan_setting_enable: device.lan_setting_enable,
      lan_dhcp: device.lan_dhcp,
      lan_ip: device.lan_ip,
      lan_mask: device.lan_mask,
      lan_gateway: device.lan_gateway,
      lan_dns1: device.lan_dns1,
      lan_dns2: device.lan_dns2,
    },
    resolver: yupResolver(schema),
  });

  const editDeviceLanSetting: SubmitHandler<ReqAcEditDeviceLan> = (data) => {
    console.log(data);
    console.log('edit');
    if(appstate.getAccountId() != undefined || appstate.getAccountId() != ""){
      editDeviceLan(Number(device_id), data);
      if (!error && !loading) {
        alert('デバイス情報を編集しました。');
        hisotry.push('/devices');
      }
    }
  };

  useEffect(() => {
    setValue('lan_setting_enable', device.lan_setting_enable);
    setValue('lan_dhcp', device.lan_dhcp);
    setValue('lan_ip', device.lan_ip);
    setValue('lan_mask', device.lan_mask);
    setValue('lan_gateway', device.lan_gateway);
    setValue('lan_dns1', device.lan_dns1);
    setValue('lan_dns2', device.lan_dns2);
  }, [device]);

  return (
    <Box maxWidth="sm" sx={{ py: 5 }}>
      <Stack spacing={2} sx={{ mb: 5 }}>
        <Typography align="left" component="div">
          ネットワーク設定変更
        </Typography>
        <FormControl fullWidth size="small" required error={'lan_setting_enable' in errors}>
          <InputLabel id="form-device-type-label">LAN設定変更</InputLabel>
          <Controller
            render={({ field }) => (
              <Select id="form-device-type" label="LAN設定変更*" {...field}>
                <MenuItem value={1}>有効</MenuItem>
                <MenuItem value={0}>無効</MenuItem>
              </Select>
            )}
            control={control}
            name="lan_setting_enable"
            defaultValue={device.lan_setting_enable}
          />
          <FormHelperText>{errors.lan_setting_enable?.message}</FormHelperText>
        </FormControl>
        <FormControl fullWidth size="small" required error={'lan_dhcp' in errors}>
          <InputLabel id="form-device-type-label">DHCP</InputLabel>
          <Controller
            render={({ field }) => (
              <Select id="form-device-type" label="DHCP*" {...field}>
                <MenuItem value={1}>有効</MenuItem>
                <MenuItem value={0}>無効</MenuItem>
              </Select>
            )}
            control={control}
            name="lan_dhcp"
            defaultValue={device.lan_dhcp}
          />
          <FormHelperText>{errors.lan_dhcp?.message}</FormHelperText>
        </FormControl>

        <Controller
          render={({ field }) => (
            <TextField
              required
              label="IPアドレス"
              {...field}
              error={'lan_ip' in errors}
              helperText={errors.lan_ip?.message}
              size="small"
            />
          )}
          control={control}
          name="lan_ip"
          defaultValue={device.lan_ip}
        />
        <Controller
          render={({ field }) => (
            <TextField
              label="サブネットマスク"
              {...field}
              error={'lan_mask' in errors}
              helperText={errors.lan_mask?.message}
              size="small"
            />
          )}
          control={control}
          name="lan_mask"
          defaultValue={device.lan_mask}
        />
        <Controller
          render={({ field }) => (
            <TextField
              label="デフォルトゲートウェイ"
              {...field}
              error={'lan_gateway' in errors}
              helperText={errors.lan_gateway?.message}
              size="small"
            />
          )}
          control={control}
          name="lan_gateway"
          defaultValue={device.lan_gateway}
        />
        <Controller
          render={({ field }) => (
            <TextField
              label="DNSサーバ1"
              {...field}
              error={'lan_dns1' in errors}
              helperText={errors.lan_dns1?.message}
              size="small"
            />
          )}
          control={control}
          name="lan_dns1"
          defaultValue={device.lan_dns1}
        />
        <Controller
          render={({ field }) => (
            <TextField
              label="DNSサーバ2"
              {...field}
              error={'lan_dns2' in errors}
              helperText={errors.lan_dns2?.message}
              size="small"
            />
          )}
          control={control}
          name="lan_dns2"
          defaultValue={device.lan_dns2}
        />
      </Stack>
      <div dir="rtl">
        <Button variant="contained" sx={{ my: 2, px: 3 }} onClick={handleSubmit(editDeviceLanSetting)}>
          設定
        </Button>
      </div>
    </Box>
  );
});
