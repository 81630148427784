import React from 'react';
import axios from 'axios';
import { ReqAcEditDeviceLan } from '../types/ce-api/Devices';
import { ApiUrl } from '../conf/config';

export const useEditDeviceLan = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const editDeviceLan = (id: number, device: ReqAcEditDeviceLan) => {
    let token:string|null = sessionStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    setError(false);
    setLoading(true);

    axios
      .put(`${ApiUrl}/ac/api/v1/devices/${id}/lan`, device, config)
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { editDeviceLan, loading, error };
};
